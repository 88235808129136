export const environment = {
    production: true,
    audience: 'https://siemens-00130.eu.auth0.com/api/v2/',
    issuer: 'https://siemens-00130.eu.auth0.com/',
    logoutUrl : 'https://rsct.siemens.com/logout?response_type=code&client_id=',
    clientId: 'lDwKQM6CQMXZKGXjKjcFtxEUprlteiTR',
    scope: 'openid profile email https://rsct.siemens.com/backend',
    showDebugInformation: false,
    sentryDNS: 'https://8f3b222b3c9d486ba804e51139f5f758@crash.siemens.com/211',
    name: "Production",
    version: '0c689b266079fae14436338f9e26258246f82a95',
    labomApi:'https://api.labom.com/dmprog'
};
