/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file is used to display the disclaimer.
 *
 *
*/
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-disclaimer-modal',
  templateUrl: './disclaimer-modal.component.html',
  styleUrls: ['./disclaimer-modal.component.scss'],
})
export class DisclaimerModalComponent implements OnInit {
  public data: string;
  url: string;
  display: boolean;

  constructor(
    private readonly modalService: BsModalService,
    private readonly sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.showDisclaimerData();
  }

  /**
   * To hide the modal as soon as yes or no option is selcted
   */
  public hideModal($event) {
    this.modalService.hide();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * To route to home page if yes option is selected
   * */
  public showDisclaimerData() {
    const language = sessionStorage.getItem('user_lang');
    if (language === 'en') {
      this.display = true;
    }
  }
}
