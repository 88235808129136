
class DiaphragmSeal
{
    id: string;
    code: string;
    description: string;
    type: string;
    maxExtensionLength: number;
    tMin: number;
    tMax: number;
    datasheetNo: string;
}

class DiaphragmSealVersion
{
    id: number;
    code: string;
    description: string;
    category: string;
    pMax: number;
    datasheetNo: string;
}

class DiaphragmSealFoil
{
    id: number;
    code: string;
    description: string;
    functionUnitID: number;
    tMax: number;
    isDefault:boolean;
    diameter: string;
}

class MountingElement1
{
    id: number;
    code: string;
    description: string;
    isdefault: boolean;
}

class Fluid
{
    id: number;
    code: string;
    description: string;
    tMin: number;
    tMax: number;
    tMinProcessDefault: number;
    tMaxProcessDefault: number;
}

export class RemoteSealPlusSide {
    diaphragmSeal:DiaphragmSeal;
    diaphragmSealVersion:DiaphragmSealVersion;
    diaphragmSealFoil:DiaphragmSealFoil;
    mountingElement1:MountingElement1;
    mountingElement2:number | null= null;
    fluid:Fluid;
    capillary: Capillary;
    tAmbientMin = 0;
    tAmbientNormal = 0;
    tAmbientMax = 0;
    tProcessMin = 0;
    tProcessNormal = 0;
    tProcessMax = 0;
    negativePressureService :string = null;
    heightInstrumentOverDiaphragmSeal = 0;
    extensionLength  = 0;
    constructor() {
        this.diaphragmSeal= new DiaphragmSeal();
        this.diaphragmSealVersion=new DiaphragmSealVersion();
        this.diaphragmSealFoil= new DiaphragmSealFoil();
        this.mountingElement1= new MountingElement1();
        this.capillary = new Capillary();
    }
}

class Capillary {
    length = 0;
    diameter = 0;
}
class CapillaryForForntEnd {
    length :string = null;
    diameter :string = null;
}

class Instrument {
    instrument :InstrumentType;
    instrumentVersion :InstrumentVersion;
    constructor() {
        this.instrument = new InstrumentType();
        this.instrumentVersion = new InstrumentVersion();
    }
}

class InstrumentType
{
    code:string;
    datasheetNo:string;
    description:string;
    id:string;
    tMax:number;
    tMaxAmbientDefault:number;
    tMin:number;
    tMinAmbientDefault:number;
    type:string;
}

class InstrumentVersion
{
    code:string;
    description:string;
    id:string;
    maxSpan:number;
    maxTurndown:number;
    pMax:number;
    pMaxStatic:number;
    pMin:number;
    pNominal:number;
}
class Units {
    distance :string = null;
    pressure :string = null;
    temperature :string = null;
}

class TypeOfSystem {
    single :boolean| null = null;
    differential:{symmetric:boolean| null} = { symmetric: null };
}

class FrontDataMinusSide {
    showAllMountingElements :string = null;
    capillary: CapillaryForForntEnd;
    extensionLength :string = null;
    heightDifference :string = null;
    constructor() {
        this.capillary = new CapillaryForForntEnd();
    }
}

class FrontDataPlusSide {
    showAllMountingElements :string = null;
    capillary: CapillaryForForntEnd;
    extensionLength :string = null;
    heightDifference :string = null;
    constructor() {
        this.capillary = new CapillaryForForntEnd();
    }
}

class UnitsObject {
    maxMeasuringSpan :string = null;
    plusSide: FrontDataPlusSide;
    minusSide: FrontDataMinusSide;
    constructor() {
        this.plusSide = new FrontDataPlusSide();
        this.minusSide = new FrontDataMinusSide();
    }
}

export class LoadSavedFrontendData {
    units: Units;
    typeOfSystem: TypeOfSystem;
    UnitsObject: UnitsObject;
    jsonVersion :string = null;
    constructor() {
        this.units = new Units();
        this.typeOfSystem = new TypeOfSystem();
        this.UnitsObject = new UnitsObject();
    }
}


export class MeasuringpointObject
{
    requestId: number;
    supplierId: number;
    userName: string;
    uniqueId: string;
    tagNumber: string;
    project: string;
    endCustomer: string;
    comments: string;
    nonStandard: boolean;
    calculationStatus: string;
    remotePlusMlfb: string;
    remotePlusMlfbOptions: string;
    remotePlusMlfbTexts: string;
    remoteMinusMlfb: string;
    remoteMinusMlfbOptions: string;
    remoteMinusMlfbTexts: string;
    transmitterMlfb: string;
    transmitterMlfbOptions: string;
    transmitterMlfbTexts: string;
    saveDate: string;
    archivDate: string;
    version: string;
    fillingLiquidFactorValue: number
}

export class OptionType
{
    optId: number;
    optCode: string;
    optValue=true;
    optDescription: string;
    optCategory: string;
    optSide: string;
}
export class LoadInternalConfigurationData {
  measuringpointObject :MeasuringpointObject;
  optionObject:OptionType[];
  configurationObject :LoadSavedData;
}

class ResponseTimes
{
    tauForT1:number;
    tauForT2:number;
    tauForT3:number;
}

class Utilization
{
    customerOffsetAdjustment:number;
    endPressure:number;
    endPressureMinus:number;
    gradientAdjustment:number;
    offsetAdjustment:number;
    spanAdjustment:number;
    volume:number;
    volumeMinus:number;
}

class Errors
{
    errorByAmbientDelta:number;
    errorByAmbientTemperature:number;
    errorByProcessDelta:number;
    errorByProcessTemperature:number;
}

 export class LabomResult
 {
    backendVersion:string;
    calculationDate:string;
    internals:string;
    mbarToPercentFactor:number;
    responseTimes:ResponseTimes;
    utilization:Utilization;
    errors:Errors;
    constructor()
    {
        this.errors= new Errors();
        this.utilization= new Utilization();
        this.responseTimes= new ResponseTimes();
        
    }
 }


export class LoadSavedData {
  id :number |null = null;
  customID :string = null;
  referenceID :number| null = null;
  referenceGUID :string = null;
  description :string = null;
  referenceNo :string = null;
  customer :string = null;
  comment :string = null;
  commen2 :string = null;
  saveDate :string = null;
  storedBy :string = null;
  language :string = null;
  instrument: Instrument;
  instrument2 :Instrument | null = null;
  pMin = 0;
  pMax = 0;
  pMaxStatic :number |null = null;
  plusSide: RemoteSealPlusSide;
  minusSide :RemoteSealPlusSide | null = null;
  tAmbient1 :number = null;
  tAmbient2 :number = null;
  tAmbient3 :number = null;
  frontendVersion :string = null;
  frontendData :string = null;
  calculationMode :string = null;
  result :LabomResult ;
  constructor() {
      this.instrument = new Instrument();
      this.plusSide = new RemoteSealPlusSide();
      this.result= new LabomResult();
  }
}
