/**
 * Copyright (C) Siemens AG 2016-2023 ALL RIGHTS RESERVED. CONFIDENTIAL
 *
 * About File description :
 *
 * This file uses city and country mapping
 *
 */

export const cityAndcountryMappingObject = {
    Bogota: 'Colombia',
    Casablanca: 'Morocco',
    Mexico_City: 'Mexico',
    Cancun: 'Mexico',
    Merida: 'Mexico',
    Monterrey: 'Mexico',
    Matamoros: 'Mexico',
    Mazatlan: 'Mexico',
    Chihuahua: 'Mexico',
    Ojinaga: 'Mexico',
    Hermosillo: 'Mexico',
    Tijuana: 'Mexico',
    Bahia_Banderas: 'Mexico',
    Paris: 'France',
    Busingen: 'Germany',
    Berlin: 'Germany',
    Madrid: 'Spain',
    Ceuta: 'Spain',
    Canary: 'Spain',
    Jakarta: 'Indonesia',
    Pontianak: 'Indonesia',
    Makassar: 'Indonesia',
    Jayapura: 'Indonesia',
    Bangkok:'Bangkok'
};
