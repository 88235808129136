/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file DataStoreService service stores the configuration data.
 *
 *
*/

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BehaviorSujectIntialize } from './data-store';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DataStoreService {
    callToggle = new Subject();
    public behaviorSubjectConfiguration = null;
    public ambientBehaviorSubject = null;
    public processBehaviorSubject = null;
    public vaccumServiceBehaviorSubject = null;
    public mesauringRange = null;
    public staticPressure = null;
    public instrumentType = null;
    public distanceUnit = null;
    public pressureUnit:BehaviorSujectIntialize = null;
    public temperatureUnit = null;
    public languageChangeOnLoad = null;
    public fillingFluidCodeEFilter = null;

    constructor() {
        this.behaviorSubjectConfiguration = new BehaviorSujectIntialize();
        this.ambientBehaviorSubject = new BehaviorSujectIntialize();
        this.processBehaviorSubject = new BehaviorSujectIntialize();
        this.vaccumServiceBehaviorSubject = new BehaviorSujectIntialize();
        this.mesauringRange = new BehaviorSujectIntialize();
        this.instrumentType = new BehaviorSujectIntialize();
        this.distanceUnit = new BehaviorSujectIntialize();
        this.pressureUnit = new BehaviorSujectIntialize();
        this.temperatureUnit = new BehaviorSujectIntialize();
        this.staticPressure = new BehaviorSujectIntialize();
        this.languageChangeOnLoad = new BehaviorSujectIntialize();
        this.fillingFluidCodeEFilter = new BehaviorSujectIntialize();
    }

    getData(behaviorSubject: any): Observable<any> {
        return behaviorSubject.getSubject().pipe(filter((data: any) => data));
    }

    getCurrentData(behaviorSubject: any){
        return behaviorSubject.getValue();
    }

    setData(
        behaviorSubject: BehaviorSujectIntialize,
        data: string | Map<any, any> | object
    ) {
        behaviorSubject.setSubject(data);
    }
    createData(behaviorSubject: BehaviorSujectIntialize) {
        behaviorSubject.create();
    }
    removeData(behaviorSubject: BehaviorSujectIntialize) {
        behaviorSubject.destroy();
    }
}
