
<!-- //English// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="systemLanguage === this.englishLang && isMexico === false" aria-label="Remote Seal Calculation Tool Supplemental Terms"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Remote Seal Calculation Tool Supplemental Terms
        </h4>
      </div>
      <div class="modal-body">
        <span>
          The purpose of this Remote Seal Calculation Tool is to assist the
          user/customer through the following services:
          <ul>
            <li>
              Provision of a web-based user interface for the user/customers to
              calculate a remote seal system based on specific parameters
              depending on the individual use case.
            </li>
            <li>
              Calculation of temperature errors and response time for the remote
              seal system.
            </li>
            <li>
              Storage of the input parameters and the calculation results with
              an unique ID within the Siemens data base, which can be used later
              on by the user / customer for ordering and production.
            </li>
          </ul>
          <br />
          <span>
            The Remote Seal Calculation Tool is a web-based application that
            enables Siemens employees and customers to find the right hardware.
          </span>
          <span>
            These Remote Seal Calculation Tool Supplemental Terms
            <b>(“RSCT Terms”)</b> amend the Universal Customer Agreement
            <b>(“UCA”)</b> between Customer and Siemens solely with regard to
            the use of the Remote Seal Calculation Tool (“RSCT”) as described in
            the respective operating instructions. These RSCT Terms together
            with the UCA and other applicable Supplemental Terms mentioned in
            these documents form the agreement between the parties
            <b>(“Agreement”)</b>.
          </span>
          <br />
          <br />
          <span>
            BY CLICKING THE “ACCEPT” BUTTON OR OTHERWISE USING THE SITE, YOU
            AGREE THAT YOU HAVE READ, AND AGREE TO COMPLY WITH AND BE BOUND BY
            THESE TERMS AND CONDITIONS (UCA + SUPPLEMENTAL TERMS).
            <br />
            <br />
            IF YOU DO NOT AGREE TO BE BOUND BY THESE USER TERMS, DO NOT CLICK
            THE “ACCEPT” BUTTON.
          </span>
          <br />
          <br />
          <ol>
          <li>
            <h5>DEFINITIONS</h5>
            “Authorized User” means any employee of Siemens and/or the Customer
            who creates an individual ID for the use of the RSCT.
            <br />
            <br />
            “Customer” means a direct or indirect, internal, or external
            customer of Siemens or any affiliated company of Siemens.
            <br />
            <br />
            “Description” means the description of the RSCT including the
            purpose for which it may be used as mentioned in the attached
            operating instructions.
            <br />
            <br />
            “Order” means the registration form (i) where the Authorized User
            enters its personal data to create an identification, (ii) which is
            marked as “Order” and (iii) that incorporates the terms of this
            Agreement, (iv) that has been agreed by Customer through an
            Authorized User by an electronic system specified by Siemens, and
            (iii) is accepted by Siemens (as mentioned in these RSCT Terms)
            e.g., by creating the identification and granting access to the
            RSCT.
            <br />
            <br />
            “Service” means the provision of the RSCT as described in the
            operating instructions.
            <br />
            <br />
            “Territory” means the global roll-out for worldwide use.
            <br />
            <br />
          </li>
          <li>
            <h5 class="title1">GENERAL</h5>
            <ol class="bullet">
              <li>
                <b>Authorized Access and Use.</b> The RSCT may be accessed and
                used only by Authorized Users in the Territory, solely in
                accordance with this Agreement.
              </li>
              <li>
                <b>Web/Mobile.</b> To the extent this application is used by US
                Siemens employees, it may only be accessed via Siemens owned
                devices.
              </li>
              <li>
                <b>No-Charge Offering.</b> The RSCT will be offered at no-charge
                to Customers.
              </li>
              <li>
                <b>Use Rights for the RSCT.</b> Siemens grants Customer a
                nonexclusive, nontransferable, limited right to access and use
                the RSCT for Customer’s business purposes, solely in accordance
                with this Agreement and for the purpose as described in the
                Description. The right to use the RSCT shall not include the
                right to make derivative work or to include the Service into
                Customer own- or third-party products or services.
              </li>
              <li>
                <b>Account/Entitlement.</b> Customer may create accounts through
                the creation of user identification (“ID”) by Authorized Users
                (“Account”). An Account allows Customer to access the Service
                via Authorized Users. Each Authorized User must use a unique
                user identification to access and use the Service. Authorized
                User IDs may not be shared with other individuals. The Account
                will initially be valid for a period of six months. The Account
                automatically renews after the initial for the same period if
                the Authorized User logs in with its ID. If the Authorized User
                wishes to delete its ID, the Authorized User may request the
                deletion as described in the under 2.3 attached operating
                instruction. Customer will ensure that all Authorized Users
                comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
              <li>
                <b>Use of the RSCT/Disclaimer. </b> Customer may create accounts
                through the creation of user identification (“ID”) by Authorized
                Users (“Account”). An Account allows Customer to access the
                Service via Authorized Users. Each Authorized User must use a
                unique user identification to access and use the Service.
                Authorized User IDs may not be shared with other individuals.
                The Account will initially be valid for a period of six months.
                The Account automatically renews after the initial for the same
                period if the Authorized User logs in with its ID. If the
                Authorized User wishes to delete its ID, the Authorized User may
                request the deletion as described in the under 2.3 attached
                operating instruction. Customer will ensure that all Authorized
                Users comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2"> DATA and CUSTOMER CONTENT</h5>
            <ol class="bullet">
              <li>
                <b> Data Privacy Notice.</b>Neither Customer nor any Authorized
                User is permitted to upload and store personal data of third
                parties on the Platform. The processing of Customer’s personal
                data is governed by the Siemens Privacy Notice available at:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Data Retention.</b>
                <ol>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Personal Data.</b> The processing of personal data is
                    limited to the user management functionality within the
                    RSCT. User account and the associated ID which is no longer
                    active will be deleted on the basis of a semi-annual user
                    inventory and/or at the individual request of the user and
                    the respective calculation results are archived anonymously.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Customer Content. </b> Customer content will be also
                    stored / archived by Siemens, its affiliates companies or
                    subcontractors, and may be used as described in the
                    operating instructions. During the deletion process of
                    personal data the respective calculation results will be
                    archived anonymously by Siemens.
                    <br />
                    The calculation results provided by the subcontractor Labom
                    are stored there anonymously per default without
                    relationship to personal data.
                  </li>
                </ol>
              </li>
              <li>
                <b>Data Center Locations.</b> Data Center of the RSCT is located
                in Germany.
              </li>
              <li>
                <b>Use of Customer Content. </b> During and after the
                Subscription Term, Siemens, its affiliated companies, its
                business partners and subcontractors may use and publish
                Customer Content for any purpose including beyond the
                performance of the Service, in accordance with mandatory
                restrictions imposed by applicable law. Use of Content beyond
                the performance of the Service in accordance with this section
                will be at Siemens risk.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5> SIEMENS CONTACT</h5>
            Customer Support
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW"
              target="_blank"
              >https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW</a
            >
          </li>
        </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
        
        <button   [attr.aria-label]="'TID_IAgree' | translate" id="operating-instruction-ack" type="button" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" id="operating-instruction-cancel" type="button" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- //German// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="systemLanguage === this.germanLang && isMexico === false" aria-label="Berechnungs-Tool für Druckmittler – Ergänzende Bedingungen"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 d="terms-header" class="modal-title">
          Berechnungs-Tool für Druckmittler – Ergänzende Bedingungen
        </h4>
      </div>
      <div class="modal-body">
        <span>
          Der Zweck des Berechnungs-Tools für Druckmittler ist, Benutzer bzw.
          Kunden bei folgenden Leistungen zu unterstützen:
          <ul>
            <li>
              Bereitstellung einer webbasierten Benutzerschnittstelle, mit der
              Benutzer bzw. Kunden individuell für jeden Anwendungsfall ein
              Druckmittlersystem auf Grundlage spezifischer Parameter berechnen
              können.
            </li>
            <li>
              Berechnung von Temperaturfehlern und Reaktionszeiten des
              Druckmittlersystems.
            </li>
            <li>
              Speicherung der Eingabeparameter und der Berechnungsergebnisse mit
              einer einzigartigen ID in der Siemens-Datenbank, die später vom
              Benutzer bzw. Kunden für Bestellungen und die Produktion verwendet
              werden kann
            </li>
          </ul>
          <br />
          <span>
            Das Berechnungs-Tool für Druckmittler ist eine webbasierte
            Anwendung, die Mitarbeitern und Kunden von Siemens hilft, die
            richtige Hardware zu finden.
          </span>
          <span>
            Die ergänzenden Bedingungen für das Berechnungs-Tool für
            Druckmittler
            <b>(„RSCT-Bedingungen“) </b> ergänzen das Universal Customer
            Agreement <b>(„UCA“)</b> zwischen dem Kunden und Siemens nur in
            Bezug auf die Nutzung des Berechnungs-Tools für Druckmittler
            („RSCT“) gemäß Beschreibung in der entsprechenden
            Bedienungsanleitung. Diese RSCT-Bedingungen samt dem UCA und anderen
            gültigen ergänzenden Bedingungen in diesen Dokumenten und/oder der
            Bestellung bilden die Vereinbarung zwischen den Parteien.<b
              >(„Vereinbarung“).
            </b>
          </span>
          <br />
          <br />
          <span>
            INDEM SIE AUF DIE SCHALTFLÄCHE „ZUSTIMMEN“ KLICKEN ODER DIESE
            WEBSITE ANDERWEITIG NUTZEN, ERKLÄREN SIE, DASS SIE DIESE
            GESCHÄFTSBEDINGUNGEN (UCA + ERGÄNZENDE BEDINGUNGEN) GELESEN HABEN,
            MIT IHNEN EINVERSTANDEN SIND UND SIE EINHALTEN.
            <br />
            <br />
            WENN SIE NICHT MIT DIESEN NUTZUNGSBEDINGUNGEN EINVERSTANDEN SIND,
            KLICKEN SIE NICHT AUF DIE SCHALTFLÄCHE „ZUSTIMMEN“.
          </span>
          <br />
          <br />
          <ol>
            <li>
            <h5>BEGRIFFSBESTIMMUNGEN</h5>
            „Autorisierter Benutzer“ bezeichnet alle Mitarbeiter von Siemens
            und/oder Siemens angegliederten Unternehmen und/oder Kunden, die
            eine individuelle ID zur Nutzung des RSCT erstellen.
            <br />
            <br />
            „Kunde“ bezeichnet einen direkten oder indirekten, internen oder
            externen Kunden von Siemens oder eines Siemens angegliederten
            Unternehmens.
            <br />
            <br />
            „Beschreibung“ ist die Beschreibung des RSCT einschließlich des
            Zwecks, für den er gemäß der Bedienungsanleitung verwendet werden
            kann.
            <br />
            <br />
            „Bestellung“ bedeutet im Rahmen der Vereinbarung das
            Registrierungsformular, (i) in dem die autorisierten Benutzer ihre
            persönlichen Daten zur Erstellung einer Kennung eingeben, (ii) das
            die Vertragsbedingungen enthält, (iv) dem die Kunden über einen
            autorisierten Benutzer durch ein von Siemens festgelegtes
            elektronisches System zustimmten und (iii) das von Siemens
            akzeptiert wird (gemäß diesen RSCT-Bedingungen), z.B. durch
            Erstellen der Kennung und Zugriffsgewährung auf den RSCT.
            <br />
            <br />
            „Dienst“ bedeutet die Bereitstellung des RSCT gemäß der
            Bedienungsanleitung.
            <br />
            <br />
            „Abonnementdauer“ bezeichnet den in den RSCT-Bedingungen
            beschriebenen Zeitraum, für den der Dienst dem Kunden zur Verfügung
            gestellt wird.
            <br />
            <br />
            „Region“ bezeichnet die Länder, in denen das Tool verfügbar sein
            wird.
         
          <br />
          <br />
        </li>
          <li>
            <h5 class="title1">ALLGEMEINES</h5>
            <ol class="bullet">
              <li>
                <b>Autorisierter Zugriff und Nutzung: </b> Nur autorisierte
                Nutzer in der Region können in Übereinstimmung mit dieser
                Vereinbarung auf den RSCT zugreifen und ihn nutzen.
              </li>
              <li>
                <b>Web/Mobil: </b>Mitarbeiter von Siemens in den USA, die diese
                Anwendung nutzen, dürfen nur über Geräte, die Eigentum von
                Siemens sind, auf die Anwendung zugreifen.
              </li>
              <li>
                <b>Kostenloses Angebot: </b>Der RSCT wird Kunden kostenlos
                angeboten.
              </li>
              
              <li>
                <b>Nutzungsrechte für den RSCT: </b> Siemens bietet Kunden ein
                nicht exklusives, nicht übertragbares, nicht
                unterlizenzierbares, beschränktes Recht für den Zugriff auf den
                und die Nutzung des RSCT für geschäftliche Zwecke des Kunden.
                Dieses Recht gilt nur in Übereinstimmung mit dieser Vereinbarung
                und für den in der Beschreibung aufgeführten Zweck. Das Recht
                zur Nutzung des RSCT berechtigt nicht zu abgeleiteter Arbeit
                oder zur Einbeziehung des Diensts in Produkte oder Dienste des
                Kunden oder Dritter.
              </li>
              <li>
                <b>Account/Berechtigung: </b> Kunden werden durch Erstellung der
                Benutzerkennung („ID“) in die Lage versetzt Accounts durch
                autorisierte Benutzer erstellen („Account“). Ein Account
                ermöglicht Kunden, über autorisierte Benutzer auf den Dienst
                zuzugreifen. Jeder autorisierte Benutzer muss eine einzigartige
                Benutzerkennung verwenden, um auf den Dienst zuzugreifen und ihn
                nutzen zu können. Die IDs der autorisierten Benutzer dürfen
                nicht an andere weitergegeben werden. Der Account ist sechs (6)
                Monate lang gültig („Abonnementdauer“). Wenn sich der
                autorisierte Benutzer mit seiner ID anmeldet, verlängert sich
                das Abonnement automatisch um den gleichen Zeitraum. Möchte der
                autorisierte Benutzer seine ID löschen, kann er die Löschung
                anfordern, wie in der Bedienungsanleitung unter 2.4 beschrieben.
                Kunden müssen darauf achten, dass sich alle autorisierten
                Benutzer an die Vereinbarung halten. Das Recht von Siemens, das
                kostenlose Angebot jederzeit nach eigenem Ermessen zu ändern, zu
                beschränken oder zu beenden, wird dadurch nicht beeinträchtigt.
                Der Kunde ist für jede Handlung oder unterlassene Handlung der
                Benutzer und jeder Person, die den Account eines autorisierten
                Benutzers gemäß dieser Vereinbarung verwendet oder darauf
                zugreift, verantwortlich.
              </li>
              <li>
                <b
                  >Use of the RSCT/Disclaimer.2.7 Verwendung des RSCT /
                  Haftungsausschluss:
                </b>
                Dem Kunden wurde erläutert und er erklärt sich damit
                einverstanden, dass Werte nach sorgfältiger Überlegung in den
                RSCT eingegeben werden müssen, da er Berechnungen und Ergebnisse
                auf Grundlage der eingegebenen Werte anzeigt. Das Ergebnis des
                RSCT ist nicht bindend und hinsichtlich der Vollständigkeit und
                technischen Korrektheit der berechneten Ergebnisse nicht
                notwendigerweise vollständig. Der RSCT soll nur einen ersten
                Überblick über die empfohlenen Produkte geben. Der Kunde ist für
                den ordnungsgemäßen Einsatz der beschriebenen Produkte
                verantwortlich. Er bleibt zudem immer der oberste
                Verantwortliche für die Sicherheit und Regeltreue. Der RSCT
                enthebt Kunden nicht von der Verantwortung, die Geräte sicher
                und fachgerecht zu nutzen, zu installieren, zu betreiben und zu
                warten. Sollte es zwischen den Empfehlungen im RSCT und denen in
                anderen Publikationen von Siemens (z.B. Katalogen) Abweichungen
                geben, gilt der Inhalt der jeweils anderen Dokumentation.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">DATEN UND KUNDENINHALTE</h5>
            <ol class="bullet">
              <li>
                <b>Datenschutzerklärung </b>Weder Kunden noch autorisierte
                Benutzer dürfen persönliche Daten von Dritten auf der Plattform
                hochladen oder speichern. Die Verarbeitung persönlicher
                Kundendaten unterliegt den Regeln in der
                Siemens-Datenschutzerklärung, die Sie hier einsehen können:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Datenhaltung</b>
                <ul>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Persönliche Daten </b> Die Verarbeitung persönlicher
                    Daten ist auf Funktionen zur Benutzerverwaltung im RSCT
                    beschränkt. Nicht mehr aktive Benutzeraccounts und mit ihnen
                    verknüpfte IDs werden bei der halbjährlichen
                    Benutzerinventur und/oder auf individuellen Wunsch der
                    Benutzer hin gelöscht. Die jeweiligen Berechnungsergebnisse
                    werden anonym archiviert.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Kundeninhalte </b> Kundeninhalte werden von Siemens,
                    Siemens angegliederten Unternehmen oder Subunternehmen
                    ebenfalls gespeichert/archiviert und können wie in der
                    Bedienungsanleitung angegeben verwendet werden. Beim Löschen
                    der persönlichen Daten werden die entsprechenden
                    Berechnungsergebnisse von Siemens anonym archiviert. Die vom
                    Subunternehmen Labom gelieferten Berechnungsergebnisse
                    werden bei diesem anonym und ohne Rückschlüsse auf
                    persönliche Daten gespeichert.
                  </li>
                </ul>
              </li>
              <li>
                <b>Standort des Datenzentrums </b> Das Datenzentrum des RSCT für
                Siemens und dessen Subunternehmen Labom befindet sich in
                Deutschland.
              </li>
              <li>
                <b>Verwendung von Kundeninhalten </b> Während und nach der
                Abonnementdauer können Siemens, Siemens angegliederte
                Unternehmen und Subunternehmen Kundeninhalte verwenden und
                veröffentlichen. Der Zweck kann dabei über die Erfüllung des
                Diensts hinausgehen, solange alle obligatorischen
                Einschränkungen durch geltende Gesetze eingehalten werden. Die
                Nutzung von Inhalten jenseits der Leistungserbringung gemäß
                diesem Abschnitt geschieht auf Risiko von Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>ANSPRECHPARTNER BEI SIEMENS</h5>
            Kundensupport
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=de-WW"
              target="_blank"
              >https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=de-WW</a
            >
          </li>
          </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-wrap: nowrap;">
        
        <button [attr.aria-label]="'TID_IAgree' | translate" type="button" id="operating-instruction-ack" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" type="button" id="operating-instruction-cancel" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- //French// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="systemLanguage === this.frenchLang && isMexico === false" aria-label="Conditions supplémentaires pour l'outil RSCT de calcul du séparateur"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Conditions supplémentaires pour l'outil RSCT de calcul du séparateur
        </h4>
      </div>
      <div class="modal-body">
        <span>
          L'outil RSCT de calcul du séparateur a pour objet d'aider
          l'utilisateur/le client par la mise à disposition des services
          suivants :
          <ul>
            <li>
              Fourniture d'une interface utilisateur Web aux
              utilisateurs/clients pour calculer un système de séparateur sur la
              base de paramètres spécifiques selon le cas d'application
              individuel
            </li>
            <li>
              Calcul des erreurs de température et du temps de réponse pour le
              système de séparateur
            </li>
            <li>
              Stockage des paramètres d'entrée et des résultats de calcul avec
              une ID unique dans la base de données de Siemens, avec possibilité
              d'utilisation ultérieure par l'utilisateur ou le client pour la
              commande ou la production
            </li>
          </ul>
          <br />
          <span>
            L'outil RSCT de calcul du séparateur est une application Web
            permettant aux employés et aux Clients de Siemens de trouver le
            matériel adéquat.
          </span>
          <span>
            Les présentes Conditions supplémentaires relatives à l'outil RSCT de
            calcul du séparateur
            <b>("Conditions RSCT") </b> modifient l’Universal Customer Agreement
            <b>("UCA")</b> entre le Client et Siemens uniquement en ce qui
            concerne l'utilisation de l'Outil de calcul du séparateur ("RSCT")
            tel que décrit dans les instructions de service respectives. Les
            présentes Conditions RSCT, ainsi que l'UCA et les autres Conditions
            supplémentaires applicables mentionnées dans ces documents et/ou
            dans le formulaire de Commande constituent l'accord entre les
            parties<b>("Accor").</b>
          </span>
          <br />
          <br />
          <span>
            EN CLIQUANT SUR LE BOUTON D'ACCEPTATION OU EN UTILISANT LE SITE DE
            QUELQUE MANIÈRE QUE CE SOIT, VOUS RECONNAISSEZ AVOIR LU LES
            PRÉSENTES CONDITIONS, VOUS Y CONFORMER ET ÊTRE LIÉ PAR ELLES (UCA +
            CONDITIONS SUPPLÉMENTAIRES).
            <br />
            <br />
            SI VOUS REFUSEZ D'ÊTRE LIÉ PAR CES CONDITIONS D'UTILISATION, NE
            CLIQUEZ PAS SUR LE BOUTON D'ACCEPTATION.
          </span>
          <br />
          <br />
         <ol>
          <li>
            <b>DÉFINITIONS</b>
            <br />
            "Utilisateur autorisé" désigne tout employé de Siemens et/ou de
            sociétés affiliées à Siemens et/ou du Client qui crée une ID
            individuelle pour l'utilisation de l'outil RSCT.
            <br />
            <br />
            "Client" désigne un client direct ou indirect, interne ou externe de
            Siemens ou de toute société affiliée à Siemens.
            <br />
            <br />
            "Description" désigne la description de l'outil RSCT, notamment le
            but dans lequel il peut être utilisé comme mentionné dans les
            instructions de service.
            <br />
            <br />
            Dans le contexte de l'Accord, "Commande" désigne le formulaire
            d'enregistrement (i) où l'Utilisateur autorisé saisit ses données
            personnelles, afin de créer une identification, (ii) qui comprend
            les conditions du présent Accord, (iv) qui a été approuvé par le
            Client via un Utilisateur autorisé par un système électronique
            spécifié par Siemens et (iii) qui est accepté par Siemens (comme
            mentionné dans les présentes Conditions RSCT), par exemple par la
            création de l'identification et l'octroi de l'accès à RSCT.
            <br />
            <br />
            "Service" désigne la fourniture de l'outil RSCT tel que décrit dans
            les instructions de service.
            <br />
            <br />
            "Période d'abonnement" désigne la période décrite dans les présentes
            Conditions RSCT pendant laquelle le Service est fourni au Client.
            <br />
            <br />
            "Territoire" désigne les pays dans lesquels l'outil sera accessible.
            <br />
            <br />
          </li>
          <li>
            <h5 class="title1">GÉNÉRALITÉS</h5>
            <ol class="bullet">
              <li>
                <b>Accès et utilisation autorisés. </b> Seuls les
                Utilisateurs autorisés peuvent accéder à l'outil RSCT et
                l'utiliser sur le Territoire, et ce uniquement en conformité
                avec le présent Accord.
              </li>
              <li>
                <b>Web/Mobile. </b>Dans la mesure où cette application est
                utilisée par des employés américains de Siemens, l'accès ne doit
                s'y faire que par des appareils appartenant à Siemens.
              </li>
              <li>
                <b>Offre sans frais. </b>L'outil RSCT sera offert sans frais
                aux Clients.
              </li>
              
              <li>
                <b>Droits d'utilisation pour l'outil RSCT. </b> Siemens
                accorde au Client un droit limité, non exclusif, non
                transférable et ne pouvant faire l’objet de sous-licence d'accès
                et d'utilisation de l'outil RSCT aux fins commerciales du
                Client, uniquement en vertu du présent Accord et dans le but
                décrit dans la Description. Le droit d'utiliser l'outil RSCT
                n'inclut pas le droit de réaliser des œuvres dérivées ou
                d'inclure le Service dans des produits ou services propres ou
                tiers du Client.
              </li>
              <li>
                <b>Compte/Droits. </b> Le Client peut créer des comptes par
                le biais de la création d'identifications utilisateur ("ID") par
                des Utilisateurs autorisés ("Compte"). Un Compte permet au
                Client d'accéder au Service par le biais d'Utilisateurs
                autorisés. Chaque Utilisateur autorisé doit utiliser une
                identification utilisateur unique pour accéder au Service et
                l'utiliser. Les Utilisateurs autorisés ne doivent pas partager
                leur ID avec d'autres personnes. Le Compte sera initialement
                valable pour une période de six (6) mois ("Période
                d'abonnement"). Le Compte sera automatiquement renouvelé pour la
                même période si l'Utilisateur autorisé se connecte avec son ID.
                Si l'Utilisateur autorisé souhaite supprimer son ID, il peut
                demander cette suppression comme décrit dans les instructions de
                service mentionnées sous 2.4. Le Client s'assurera que tous les
                Utilisateurs autorisés se conforment aux obligations du Client
                en vertu du présent Accord. Le droit de Siemens à modifier,
                limiter et interrompre une Offre sans frais à tout moment à la
                seule discrétion de Siemens reste applicable. Le Client est
                responsable de toute action ou tout défaut d'action de la part
                de tout Utilisateur ou de toute personne utilisant le compte ou
                accédant au compte d'un Utilisateur autorisé dans le cadre du
                présent Accord.
              </li>
              <li>
                <b>Utilisation de l'outil RSCT/Exclusion de responsabilité. </b>
                Le Client a été informé et admet que les valeurs doivent être
                saisies avec soin dans l'outil RSCT, car l'outil RSCT affiche
                des résultats et des calculs basés sur les valeurs saisies. Les
                résultats de l'outil RSCT sont fournis sous toute réserve et ne
                prétendent pas à être exhaustifs quant à l'exhaustivité et à
                l'exactitude technique des résultats calculés. L'outil RSCT a
                pour seul but de fournir un premier aperçu des produits
                recommandés. Il incombe au Client de s'assurer que les produits
                décrits sont utilisés correctement et le Client reste
                responsable en dernier ressort de la sécurité et de la
                conformité. L'outil RSCT ne dispense pas le Client de sa
                responsabilité en ce qui concerne l'utilisation, l'installation,
                l'exploitation et l'entretien sécurisés et professionnels de
                l'équipement. En cas de divergences entre les recommandations
                figurant dans l'outil RSCT et d'autres publications de Siemens –
                par exemple, mais sans s'y limiter, dans les catalogues –, le
                contenu des autres publications de Siemens a la priorité.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">DONNÉES et CONTENU DU CLIENT</h5>
            <ol class="bullet">
              <li>
                <b> Protection des données personnelles. </b>Ni le Client
                ni aucun Utilisateur autorisé n'est autorisé à télécharger et à
                stocker des données personnelles de tiers sur la Plateforme. Le
                traitement des données personnelles du Client est régi par la
                notice de Siemens sur la protection de la vie privée disponible
                à l'adresse suivante :
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Conservation des données.</b>
                <ul>
                  <li>
                    <b>Données personnelles. </b> Le traitement des données
                    personnelles est limité par la fonctionnalité de gestion des
                    utilisateurs dans l'outil RSCT. Le compte utilisateur et
                    l'ID associée qui n'est plus active seront supprimés sur la
                    base de l'inventaire semestriel des utilisateurs et/ou d'une
                    demande individuelle de l'utilisateur et les résultats de
                    calcul respectifs sont archivés sous forme anonyme.
                  </li>
                  <li>
                    <b>Contenu du Client. </b> Le contenu du Client sera
                    également stocké / archivé par Siemens, ses sociétés
                    affiliées ou ses sous-traitants et pourra être utilisé de la
                    manière décrite dans les instructions de Service. Lors de la
                    suppression des données personnelles, les résultats de
                    calcul respectifs seront archivés par Siemens sous forme
                    anonyme. Les résultats de calcul fournis par le
                    sous-traitant Labom sont stockés de manière anonyme sans
                    relation avec les données personnelles.
                  </li>
                </ul>
              </li>
              <li>
               <b>Emplacement du centre de données. </b> Le centre de
                données du RSCT se situe en Allemagne pour Siemens et son
                sous-traitant Labom.
              </li>
              <li>
                <b>Utilisation du Contenu du Client. </b> Pendant et après
                la période d'Abonnement, Siemens, ses sociétés affiliées, ses
                partenaires commerciaux et ses sous-traitants peuvent utiliser
                et publier le Contenu du Client pour quelque usage que ce soit,
                y compris au-delà du cadre de l'exécution du Service, en
                conformité avec les restrictions obligatoires imposées par la
                législation en vigueur. L'Utilisation du Contenu au-delà du
                cadre de l'exécution du Service en conformité avec cette section
                se fera aux risques de Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>CONTACT SIEMENS </h5>
            <br />
            Assistance client
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/ww/fr/sc/4868"
              target="_blank"
              >https://support.industry.siemens.com/cs/ww/fr/sc/4868</a
            >
          </li>
         </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
       
        <button [attr.aria-label]="'TID_IAgree' | translate" type="button" id="operating-instruction-ack" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" type="button" id="operating-instruction-cancel" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- //Spanish// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="systemLanguage === this.SpanishLang && isMexico === false" aria-label="Términos complementarios de la herramienta de cálculo del sello
  separador"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Términos complementarios de la herramienta de cálculo del sello
          separador
        </h4>
      </div>
      <div class="modal-body">
        <span>
          La finalidad de la herramienta de cálculo del sello separador (“RSCT”
          por sus siglas en inglés) consiste en brindar ayuda al usuario/cliente
          a través de los siguientes servicios:
          <ul>
            <li>
              Puesta a disposición de una interfaz de usuario basada en la web
              para que el usuario/cliente calcule un sistema de sello separador
              a partir de parámetros específicos según el caso de uso
              individual.
            </li>
            <li>
              Cálculo de los errores de temperatura y del tiempo de respuesta
              del sistema de sello separador.
            </li>
            <li>
              Almacenamiento de los parámetros de entrada y de los resultados
              del cálculo con un ID único en la base de datos de Siemens, que
              puede ser empleado posteriormente por el usuario/cliente para
              realizar pedidos y con fines de producción.
            </li>
          </ul>
          <br />
          <span>
            La herramienta de cálculo del sello separador es una aplicación
            basada en la web que permite a los empleados y clientes de Siemens
            encontrar el hardware adecuado.
          </span>
          <span>
            Estos Términos complementarios de la herramienta de cálculo del
            sello separador
            <b>(“Términos RSCT”) </b> ”) modifican el Acuerdo Universal de
            Clientes <b>("UCA"</b>por sus siglas en inglés) entre el cliente y
            Siemens únicamente en lo que respecta al uso de la herramienta de
            cálculo del sello separador (“RSCT”) tal y como se describe en las
            instrucciones de servicio correspondientes. Los presentes Términos
            RSCT, junto con el UCA y otros términos complementarios aplicables
            mencionados en estos documentos y/o en el pedido, constituyen el
            acuerdo entre las partes <b>("Acuerdo").</b>
          </span>
          <br />
          <br />
          <span>
            AL HACER CLIC EN EL BOTÓN “ACEPTAR” O AL UTILIZAR EL SITIO DE
            CUALQUIER OTRO MODO, USTED ACEPTA HABER LEÍDO Y ESTAR DE ACUERDO CON
            ESTOS TÉRMINOS Y CONDICIONES (UCA + TÉRMINOS COMPLEMENTARIOS).
            <br />
            <br />
            SI NO ESTÁ DE ACUERDO CON ESTAS CONDICIONES DE USO, NO HAGA CLIC EN
            EL BOTÓN “ACEPTAR”.
          </span>
          <br />
          <br />
          <ol>
            <li>
            <h5>DEFINICIONES</h5>
            <br />
            “Usuario autorizado” se refiere a cualquier empleado de Siemens y/o
            de las empresas afiliadas a Siemens y/o al cliente que cree un ID
            individual para el uso de la RSCT.
            <br />
            <br />
            “Cliente” se refiere a un cliente directo o indirecto, interno o
            externo de Siemens o de cualquier empresa afiliada a Siemens.
            <br />
            <br />
            “Descripción” se refiere a la descripción de la RSCT, incluida la
            finalidad para la que puede utilizarse, tal como se menciona en las
            instrucciones de servicio.
            <br />
            <br />
            “Pedido” se refiere, en el contexto del Acuerdo, al formulario de
            registro (i) en el que el Usuario autorizado introduce sus datos
            personales para crear una identificación, y (ii) que incorpora los
            Términos del presente Acuerdo, (iv) que ha sido acordado por el
            Cliente a través de un Usuario autorizado mediante un sistema
            electrónico especificado por Siemens, y (iii) que es aceptado por
            Siemens (tal y como se menciona en estos Términos RSCT), p. ej., al
            crear la identificación y conceder acceso a la RSCT.
            <br />
            <br />
            “Servicio” se refiere al suministro de la RSCT, tal y como se
            describe en las instrucciones de servicio.
            <br />
            <br />
            “Plazo de suscripción” se refiere al período de tiempo descrito en
            estos Términos RSCT durante el cual el servicio se pone a
            disposición del Cliente.
            <br />
            <br />
            “Territorio” se refiere a los países en los que será posible acceder
            a la herramienta.
            <br />
            <br />
            </li>
         
          <li>
            <h5 class="title1">DISPOSICIONES GENERALES</h5>
            <ol class="bullet">
              <li>
                 <b>Acceso y uso autorizados</b> Sólo los usuarios
                autorizados en el Territorio pueden acceder a la RSCT y
                utilizarla, únicamente de conformidad con el presente Acuerdo.
              </li>
              <li>
                 <b>Web/Móvil. </b>En la medida en que esta aplicación sea
                utilizada por los empleados de Siemens de EE. UU., sólo se podrá
                acceder a ella a través de dispositivos propiedad de Siemens.
              </li>
              <li>
                 <b>Oferta sin cargos.</b>La RSCT se ofrecerá a los Clientes
                sin cargo alguno.
              </li>
             
              <li>
                <b>Derechos de uso de la RSCT. </b> Siemens concede al
                Cliente un derecho no exclusivo, intransferible, no
                sublicenciable y limitado a acceder y utilizar la RSCT para
                fines empresariales del cliente, únicamente en virtud del
                presente Acuerdo y para los fines citados en la Descripción. El
                derecho a utilizar la RSCT no incluye el derecho a efectuar
                trabajos derivados o a incorporar el Servicio en productos o
                servicios propios o de terceros.
              </li>
              <li>
                <b>Cuenta/Derechos. </b> . El Cliente puede crear cuentas
                mediante la creación de una identificación de usuario (“ID”) por
                parte de los Usuarios autorizados (“Cuenta”). Una Cuenta permite
                al Cliente acceder al Servicio a través de Usuarios autorizados.
                Cada Usuario autorizado debe utilizar una identificación de
                usuario única para acceder al Servicio y utilizarlo. Los ID de
                los Usuarios autorizados no pueden compartirse con otras
                personas. La Cuenta será válida inicialmente por un período de
                seis (6) meses (“Plazo de suscripción”). La Cuenta se renueva
                automáticamente por el mismo periodo si el Usuario autorizado
                inicia sesión con su ID. Si el Usuario autorizado desea eliminar
                su ID, puede solicitar la eliminación tal y como se describe en
                el apartado 2.4 de las instrucciones de servicio
                correspondientes. El Cliente se asegurará de que todos los
                Usuarios autorizados cumplan con las obligaciones del Cliente en
                virtud del presente Acuerdo. El derecho de Siemens a modificar,
                limitar y suspender una Oferta sin cargos en cualquier momento y
                a su exclusiva discreción no se ve afectado. El Cliente se hace
                responsable de cualquier acto u abstención de cualquier Usuario
                o de cualquier persona que utilice o acceda a la Cuenta de un
                Usuario autorizado en relación con este Acuerdo.
              </li>
              <li>
                
                <b>Uso de la RSCT/Descargos de responsabilidad.</b>
                Se advierte al Cliente y éste acepta que los valores deben
                introducirse cuidadosamente en la RSCT, ya que la RSCT muestra
                los resultados y los cálculos con base en los valores
                introducidos. El resultado de la RSCT no es vinculante y no
                pretende ser completo en cuanto a la integridad y la idoneidad
                técnica de los resultados calculados. La RSCT sólo busca ofrecer
                una primera visión general de los productos recomendados. El
                Cliente es responsable de garantizar que los productos descritos
                se utilicen correctamente y sigue siendo siempre responsable en
                último término de la seguridad y el cumplimiento. Esta RSCT no
                exime al Cliente de la responsabilidad de instalar, utilizar,
                operar y mantener el equipo de forma segura y profesional. Si
                hubiera divergencias entre las recomendaciones dadas en la RSCT
                y otras publicaciones de Siemens, p. ej. catálogos pero sin
                limitarse a estos, entonces tienen prioridad los contenidos de
                las otras publicaciones de Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">DATOS y CONTENIDO DEL CLIENTE</h5>
            <ol class="bullet">
              <li>
                <b>Aviso de privacidad de datos. </b>Ni el Cliente ni ningún
                Usuario autorizado están autorizados a cargar y almacenar datos
                personales de terceros en la plataforma. El tratamiento de los
                datos personales del Cliente se rige por el Aviso de Privacidad
                de datos de Siemens disponible en:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Retención de datos.</b>
                <ul>
                  <li>
                    <b>Datos personales. </b> El tratamiento de los datos
                    personales se limita a la función de gestión de los usuarios
                    en el marco de la RSCT. La Cuenta de usuario y el ID
                    asociado que ya no estén activos se eliminarán sobre la base
                    de un inventario semestral de Usuarios y/o a petición
                    individual del Usuario, y los respectivos resultados de los
                    cálculos se archivarán de forma anónima.
                  </li>
                  <li>
                    <b>Contenido del Cliente. </b> El Contenido del cliente
                    también será almacenado/archivado por Siemens, sus empresas
                    afiliadas o subcontratistas, y podrá ser utilizado como se
                    describe en las instrucciones de servicio. Durante el
                    proceso de eliminación de los datos personales, los
                    respectivos resultados de los cálculos serán archivados de
                    forma anónima por Siemens. Los resultados de los cálculos
                    proporcionados por el subcontratista Labom se almacenarán
                    allí de forma anónima sin relación con los datos personales.
                  </li>
                </ul>
              </li>
              <li>
                 <b>Ubicación del centro de datos. </b> El centro de datos de
                la RSCT correspondiente a Siemens y su subcontratista Labom está
                ubicado en Alemania.
              </li>
              <li>
                <b>Uso de Contenido del Cliente. </b> Durante y después del
                Plazo de suscripción, Siemens, sus empresas afiliadas, sus
                partners comerciales y sus subcontratistas podrán utilizar y
                publicar el Contenido del Cliente para cualquier fin, incluso
                más allá de la prestación del Servicio, de acuerdo con las
                restricciones obligatorias impuestas por la legislación
                aplicable. El uso del Contenido más allá de la prestación del
                Servicio se efectuará, de acuerdo con esta sección, bajo la
                responsabilidad de Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>CONTACTO EN SIEMENS </h5>
            <br />
            Customer Support
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/ww/es/sc/4868"
              target="_blank"
              >https://support.industry.siemens.com/cs/ww/es/sc/4868</a
            >
          </li>
          </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
       
        <button [attr.aria-label]="'TID_IAgree' | translate" type="button" id="operating-instruction-ack" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" type="button" id="operating-instruction-cancel" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- //Indonesian// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="systemLanguage === this.indonesianLang && isMexico === false " aria-label="Ketentuan Tambahan Remote Seal Calculation Tool (Alat Penghitungan
  Segel Jarak Jauh)"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Ketentuan Tambahan Remote Seal Calculation Tool (Alat Penghitungan
          Segel Jarak Jauh)
        </h4>
      </div>
      <div class="modal-body">
        <span>
          Tujuan Remote Seal Calculation Tool (Alat Penghitungan Segel Jarak
          Jauh) ini adalah untuk membantu pengguna/pelanggan melalui layanan
          berikut:
          <ul>
            <li>
              Penyediaan antarmuka pengguna berbasis web bagi pengguna/pelanggan
              untuk menghitung sistem segel jarak jauh berdasarkan parameter
              khusus tergantung pada masing-masing kasus penggunaan.
            </li>
            <li>
              Penghitungan kesalahan suhu dan waktu respons untuk waktu segel
              jarak jauh.
            </li>
            <li>
              Penyimpanan parameter input dan hasil penghitungan dengan ID unik
              dalam database Siemens, yang dapat digunakan nanti oleh pengguna /
              pelanggan untuk pemesanan dan produksi.
            </li>
          </ul>
          <br />
          <span>
            Remote Seal Calculation Tool (Alat Penghitungan Segel Jarak Jauh)
            adalah aplikasi berbasis web yang memungkinkan karyawan dan
            Pelanggan Siemens menemukan perangkat keras yang tepat.
          </span>
          <span>
            Ketentuan Tambahan Remote Seal Calculation Tool (Alat Penghitungan
            Segel Jarak Jauh) (“Ketentuan
            <b>RSCT</b>”) ini mengubah Perjanjian Pelanggan Universal ("
            <b>UCA</b>”/Universal Customer Agreement) antara Pelanggan dan
            Siemens hanya dalam kaitannya dengan penggunaan Alat Penghitungan
            Segel Jarak Jauh (“RSCT”/Remote Seal Calculation Tool) sebagaimana
            dijelaskan dalam masing-masing petunjuk pengoperasian. Ketentuan
            RSCT ini bersama dengan UCA dan Ketentuan Tambahan yang berlaku
            lainnya sebagaimana disebutkan dalam dokumen ini dan/atau formulir
            Pemesanan, perjanjian antar pihak-pihak <b>("Perjanjian")</b>.
          </span>
          <br />
          <br />
          <span>
            DENGAN MENGKLIK TOMBOL “ACCEPT” (TERIMA) ATAU MENGGUNAKAN SITUS INI,
            ANDA MENYETUJUI BAHWA ANDA TELAH MEMBACA, DAN MENYETUJUI UNTUK PATUH
            DAN TERIKAT OLEH KETENTUAN DAN SYARAT INI (UCA + KETENTUAN
            TAMBAHAN).
            <br />
            <br />
            JIKA ANDA TIDAK MENYETUJUI UNTUK TERIKAT OLEH KETENTUAN PENGGUNA
            INI, JANGAN MENGKLIK TOMBOL “ACCEPT” (TERIMA).
          </span>
          <br />
          <br />
          <ol>
            <li>
            <h5>DEFINISI</h5>
            “Pengguna Resmi” berarti karyawan Siemens dan/atau
            perusahaan-perusahaan yang berafiliasi dengan Siemens dan/atau
            Pelanggan yang membuat ID individu untuk penggunaan RSCT.
            <br />
            <br />
            “Pelanggan” berarti pelanggan internal atau eksternal Siemens atau
            salah satu perusahaan yang berafiliasi dengan Siemens baik secara
            langsung atau tidak langsung.
            <br />
            <br />
            “Deskripsi” berarti deskripsi RSCT, termasuk tujuan penggunaannya
            sebagaimana disebutkan dalam petunjuk pengoperasian.
            <br />
            <br />
            “Pemesanan” berarti konteks Perjanjian, formulir pendaftaran (i)
            yang mana Pengguna Resmi memasukkan data pribadinya untuk membuat
            identifikasi, dan (ii) yang menyertakan ketentuan Perjanjian ini,
            (iv) yang telah disetujui oleh Pelanggan melalui Pengguna Resmi
            dengan sistem elektronik yang ditentukan oleh Siemens, dan (iii)
            diterima oleh Siemens (sebagaimana disebutkan dalam Ketentuan RSCT
            ini) misalnya, dengan membuat identifikasi dan memberikan akses
            kepada RSCT.
            <br />
            <br />
            “Layanan” berarti ketetapan RSCT sebagaimana dijelaskan dalam
            petunjuk pengoperasian.
            <br />
            <br />
            “Ketentuan Langganan” berarti periode waktu yang dijelaskan dalam
            Ketentuan RSCT yang mana Layanan tersedia bagi Pelanggan.
            <br />
            <br />
            “Wilayah” berarti negara tempat alat ini akan tersedia.
            <br />
            <br />
            </li>
     
          <li>
            <h5 class="title1">UMUM</h5>
            <ol class="bullet">
              <li>
                <b>Akses dan Penggunaan Resmi.</b> RSCT hanya bisa diakses dan
                digunakan oleh Pengguna Resmi di Wilayah, hanya sebagaimana yang
                dinyatakan dalam Perjanjian ini.
              </li>
              <li>
                <b>Web/Seluler.</b> Selama aplikasi ini digunakan oleh karyawan
                Siemens AS, aplikasi ini hanya bisa diakses melalui perangkat
                yang dimiliki Siemens.
              </li>
              <li>
                <b>Penawaran Tanpa Biaya.</b> RSCT akan ditawarkan tanpa biaya
                kepada Pelanggan.
              </li>
              
              <li>
                <b>Hak Penggunaan untuk RSCT. </b> Siemens memberikan hak
                terbatas yang bersifat non-eksklusif, tidak dapat dipindahkan,
                tidak dapat disublisensikan kepada pelanggan untuk mengakses dan
                menggunakan RSCT untuk tujuan bisnis pelanggan, hanya
                sebagaimana yang dinyatakan dalam Perjanjian ini dan untuk
                tujuan sebagaimana dijelaskan dalam Deskripsi. Hak untuk
                menggunakan RSCT tidak boleh menyertakan hak untuk membuat karya
                turunan atau menyertakan Layanan bagi produk atau layanan yang
                dimiliki Pelanggan atau pihak ketiga.
              </li>
              <li>
                <b>Akun/Hak. </b> Pelanggan bisa membuat akun melalui pembuatan
                identifikasi pengguna (“ID”) oleh Pengguna Resmi (“Akun”).
                Dengan sebuah Akun, Pelanggan dapat mengakses Layanan melalui
                Pengguna Resmi. Setiap Pengguna Resmi harus menggunakan
                identifikasi pengguna unik untuk mengakses dan menggunakan
                Layanan. ID Pengguna Resmi tidak boleh dibagikan dengan individu
                lain. Akun pada awalnya akan berlaku selama periode enam (6)
                bulan (“Ketentuan Langganan”). Akun diperbarui secara otomatis
                dalam periode yang sama jika Pengguna Resmi login dengan ID yang
                dimilikinya. Jika Pengguna Resmi ingin menghapus ID miliknya,
                Pengguna Resmi bisa meminta penghapusan sebagaimana dijelaskan
                di bagian 2.4 merujuk pada petunjuk pengoperasian. Pelanggan
                akan memastikan bahwa semua Pengguna Resmi mematuhi kewajiban
                Pelanggan menurut Perjanjian ini. Hak Siemens untuk mengubah,
                membatasi, dan menghentikan Penawaran Tanpa Biaya kapan saja
                berdasarkan pertimbangan mutlak Siemens tetap tidak terdampak.
                Pelanggan bertanggung jawab atas tindakan apa pun atau kegagalan
                bertindak oleh Pengguna atau siapa saja yang menggunakan atau
                mengakses akun Pengguna Resmi dalam kaitannya dengan Perjanjian
                ini.
              </li>
              <li>
                <b>Penggunaan RSCT/Penafian. </b> Pelanggan disarankan dan
                menyetujui bahwa nilai-nilai harus dimasukkan secara saksama ke
                dalam RSCT, karena RSCT menampilkan hasil dan penghitungan
                berdasarkan nilai-nilai yang dimasukkan. Hasil RSCT tidak
                bersifat mengikat dan tidak diklaim sebagai hasil lengkap dalam
                kaitannya dengan kelengkapan dan kebenaran teknis hasil yang
                dihitung. RSCT hanya ditujukan untuk menyediakan ringkasan
                pertama yang mana produk direkomendasikan. Pelanggan bertanggung
                jawab dalam memastikan bahwa produk yang dijelaskan digunakan
                dengan benar dan tetap selalu bertanggung jawab atas keselamatan
                dan kepatuhannya. RSCT ini tidak membebaskan Pelanggan dari
                tanggung jawab dalam penggunaan, pemasangan, pengoperasian, dan
                servis peralatan secara aman dan profesional. Jika ada
                penyimpangan antara rekomendasi yang diberikan dalam RSCT dan
                publikasi Siemens lainnya - misalnya, tapi tidak terbatas pada
                katalog - maka isi publikasi Siemens lainnya diprioritaskan.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">ISI DATA dan PELANGGAN</h5>
            <ol class="bullet">
              <li>
                <b>Pemberitahuan Privasi Data.</b>Tidak satu pun dari Pelanggan
                atau Pelanggan Resmi yang diizinkan untuk mengunggah dan
                menyimpan data pribadi pihak ketiga ke dalam Platform ini.
                Pemrosesan data pribadi Pelanggan yang dikelola menurut
                Pemberitahuan Privasi Siemens tersedia di:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Penyimpanan Data.</b>
                <ol>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Data Pribadi. </b> Pemrosesan data pribadi dibatasi pada
                    fungsionalitas manajemen pengguna dalam RSCT. Akun pengguna
                    dan ID terkait yang tidak lagi aktif akan dihapus
                    berdasarkan inventaris pengguna setengah tahunan dan/atau
                    permintaan individu pengguna dan hasil penghitungan
                    masing-masing diarsipkan secara anonim.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Konten Pelanggan. </b> Konten pelanggan juga akan
                    disimpan/diarsipkan oleh Siemens, perusahaan afiliasinya
                    atau subkontraktornya, serta bisa digunakan sebagaimana
                    dijelaskan dalam petunjuk pengoperasian. Selama proses
                    penghapusan data pribadi, hasil penghitungan masing-masing
                    akan diarsipkan secara anonim oleh Siemens. Hasil
                    penghitungan disediakan oleh subkontraktor Labom dan
                    disimpan di sana secara anonim tanpa hubungan dengan data
                    pribadi.
                  </li>
                </ol>
              </li>
              <li>
                <b>Lokasi Pusat Data. </b> Pusat Data RSCT terletak di Jerman
                untuk Siemens dan subkontraktornya, Labom.
              </li>
              <li>
                <b>Penggunaan Konten Pelanggan. </b> Selama dan setelah
                Ketentuan Langganan, Siemens, perusahaan afiliasinya, mitra
                bisnis dan subkontraktornya bisa menggunakan dan menerbitkan
                Konten Pelanggan untuk tujuan apa pun termasuk di luar kinerja
                Layanan, sesuai dengan pembatasan wajib yang diterapkan oleh
                hukum yang berlaku. Penggunaan Konten di luar kinerja Layanan
                sesuai dengan bagian ini akan menjadi risiko Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>KONTAK SIEMENS</h5>
            Dukungan Pelanggan
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/ww/en/sc/4868"
              target="_blank"
              >https://support.industry.siemens.com/cs/ww/en/sc/4868</a
            >
          </li>
          </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
       
        <button [attr.aria-label]="'TID_IAgree' | translate" type="button" id="operating-instruction-ack" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" type="button" id="operating-instruction-cancel" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>


<!-- //Mexico// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="isMexico" aria-label="Remote Seal Calculation Tool Supplemental Terms"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Remote Seal Calculation Tool Supplemental Terms
        </h4>
      </div>
      <div class="modal-body">
        <br>
        English / English
        <br>
        <span>
          The purpose of this Remote Seal Calculation Tool is to assist the
          user/customer through the following services:
          <ul>
            <li>
              Provision of a web-based user interface for the user/customers to
              calculate a remote seal system based on specific parameters
              depending on the individual use case.
            </li>
            <li>
              Calculation of temperature errors and response time for the remote
              seal system.
            </li>
            <li>
              Storage of the input parameters and the calculation results with
              an unique ID within the Siemens data base, which can be used later
              on by the user / customer for ordering and production.
            </li>
          </ul>
          <br />
          <span>
            The Remote Seal Calculation Tool is a web-based application that
            enables Siemens employees and customers to find the right hardware.
          </span>
          <span>
            These Remote Seal Calculation Tool Supplemental Terms
            <b>(“RSCT Terms”)</b> amend the Universal Customer Agreement
            <b>(“UCA”)</b> between Customer and Siemens solely with regard to
            the use of the Remote Seal Calculation Tool (“RSCT”) as described in
            the respective operating instructions. These RSCT Terms together
            with the UCA and other applicable Supplemental Terms mentioned in
            these documents form the agreement between the parties
            <b>(“Agreement”)</b>.
          </span>
          <br />
          <br />
          <span>
            BY CLICKING THE “ACCEPT” BUTTON OR OTHERWISE USING THE SITE, YOU
            AGREE THAT YOU HAVE READ, AND AGREE TO COMPLY WITH AND BE BOUND BY
            THESE TERMS AND CONDITIONS (UCA + SUPPLEMENTAL TERMS).
            <br />
            <br />
            IF YOU DO NOT AGREE TO BE BOUND BY THESE USER TERMS, DO NOT CLICK
            THE “ACCEPT” BUTTON.
          </span>
          <br />
          <br />
          <ol>
          <li>
            <h5>DEFINITIONS</h5>
            “Authorized User” means any employee of Siemens and/or the Customer
            who creates an individual ID for the use of the RSCT.
            <br />
            <br />
            “Customer” means a direct or indirect, internal, or external
            customer of Siemens or any affiliated company of Siemens.
            <br />
            <br />
            “Description” means the description of the RSCT including the
            purpose for which it may be used as mentioned in the attached
            operating instructions.
            <br />
            <br />
            “Order” means the registration form (i) where the Authorized User
            enters its personal data to create an identification, (ii) which is
            marked as “Order” and (iii) that incorporates the terms of this
            Agreement, (iv) that has been agreed by Customer through an
            Authorized User by an electronic system specified by Siemens, and
            (iii) is accepted by Siemens (as mentioned in these RSCT Terms)
            e.g., by creating the identification and granting access to the
            RSCT.
            <br />
            <br />
            “Service” means the provision of the RSCT as described in the
            operating instructions.
            <br />
            <br />
            “Territory” means the global roll-out for worldwide use.
            <br />
            <br />
          </li>
          <li>
            <h5 class="title1">GENERAL</h5>
            <ol class="bullet">
              <li>
                <b>Authorized Access and Use.</b> The RSCT may be accessed and
                used only by Authorized Users in the Territory, solely in
                accordance with this Agreement.
              </li>
              <li>
                <b>Web/Mobile.</b> To the extent this application is used by US
                Siemens employees, it may only be accessed via Siemens owned
                devices.
              </li>
              <li>
                <b>No-Charge Offering.</b> The RSCT will be offered at no-charge
                to Customers.
              </li>
              <li>
                <b>Use Rights for the RSCT.</b> Siemens grants Customer a
                nonexclusive, nontransferable, limited right to access and use
                the RSCT for Customer’s business purposes, solely in accordance
                with this Agreement and for the purpose as described in the
                Description. The right to use the RSCT shall not include the
                right to make derivative work or to include the Service into
                Customer own- or third-party products or services.
              </li>
              <li>
                <b>Account/Entitlement.</b> Customer may create accounts through
                the creation of user identification (“ID”) by Authorized Users
                (“Account”). An Account allows Customer to access the Service
                via Authorized Users. Each Authorized User must use a unique
                user identification to access and use the Service. Authorized
                User IDs may not be shared with other individuals. The Account
                will initially be valid for a period of six months. The Account
                automatically renews after the initial for the same period if
                the Authorized User logs in with its ID. If the Authorized User
                wishes to delete its ID, the Authorized User may request the
                deletion as described in the under 2.3 attached operating
                instruction. Customer will ensure that all Authorized Users
                comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
              <li>
                <b>Use of the RSCT/Disclaimer. </b> Customer may create accounts
                through the creation of user identification (“ID”) by Authorized
                Users (“Account”). An Account allows Customer to access the
                Service via Authorized Users. Each Authorized User must use a
                unique user identification to access and use the Service.
                Authorized User IDs may not be shared with other individuals.
                The Account will initially be valid for a period of six months.
                The Account automatically renews after the initial for the same
                period if the Authorized User logs in with its ID. If the
                Authorized User wishes to delete its ID, the Authorized User may
                request the deletion as described in the under 2.3 attached
                operating instruction. Customer will ensure that all Authorized
                Users comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2"> DATA and CUSTOMER CONTENT</h5>
            <ol class="bullet">
              <li>
                <b> Data Privacy Notice.</b>Neither Customer nor any Authorized
                User is permitted to upload and store personal data of third
                parties on the Platform. The processing of Customer’s personal
                data is governed by the Siemens Privacy Notice available at:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Data Retention.</b>
                <ol>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Personal Data.</b> The processing of personal data is
                    limited to the user management functionality within the
                    RSCT. User account and the associated ID which is no longer
                    active will be deleted on the basis of a semi-annual user
                    inventory and/or at the individual request of the user and
                    the respective calculation results are archived anonymously.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Customer Content. </b> Customer content will be also
                    stored / archived by Siemens, its affiliates companies or
                    subcontractors, and may be used as described in the
                    operating instructions. During the deletion process of
                    personal data the respective calculation results will be
                    archived anonymously by Siemens.
                    <br />
                    The calculation results provided by the subcontractor Labom
                    are stored there anonymously per default without
                    relationship to personal data.
                  </li>
                </ol>
              </li>
              <li>
                <b>Data Center Locations.</b> Data Center of the RSCT is located
                in Germany.
              </li>
              <li>
                <b>Use of Customer Content. </b> During and after the
                Subscription Term, Siemens, its affiliated companies, its
                business partners and subcontractors may use and publish
                Customer Content for any purpose including beyond the
                performance of the Service, in accordance with mandatory
                restrictions imposed by applicable law. Use of Content beyond
                the performance of the Service in accordance with this section
                will be at Siemens risk.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5> SIEMENS CONTACT</h5>
            Customer Support
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW"
              target="_blank"
              >https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW</a
            >
          </li>
        </ol>
        </span>

        <br>
        Spanish / Español
        <br>
        <span>
          La finalidad de la herramienta de cálculo del sello separador (“RSCT”
          por sus siglas en inglés) consiste en brindar ayuda al usuario/cliente
          a través de los siguientes servicios:
          <ul>
            <li>
              Puesta a disposición de una interfaz de usuario basada en la web
              para que el usuario/cliente calcule un sistema de sello separador
              a partir de parámetros específicos según el caso de uso
              individual.
            </li>
            <li>
              Cálculo de los errores de temperatura y del tiempo de respuesta
              del sistema de sello separador.
            </li>
            <li>
              Almacenamiento de los parámetros de entrada y de los resultados
              del cálculo con un ID único en la base de datos de Siemens, que
              puede ser empleado posteriormente por el usuario/cliente para
              realizar pedidos y con fines de producción.
            </li>
          </ul>
          <br />
          <span>
            La herramienta de cálculo del sello separador es una aplicación
            basada en la web que permite a los empleados y clientes de Siemens
            encontrar el hardware adecuado.
          </span>
          <span>
            Estos Términos complementarios de la herramienta de cálculo del
            sello separador
            <b>(“Términos RSCT”) </b> ”) modifican el Acuerdo Universal de
            Clientes <b>("UCA"</b>por sus siglas en inglés) entre el cliente y
            Siemens únicamente en lo que respecta al uso de la herramienta de
            cálculo del sello separador (“RSCT”) tal y como se describe en las
            instrucciones de servicio correspondientes. Los presentes Términos
            RSCT, junto con el UCA y otros términos complementarios aplicables
            mencionados en estos documentos y/o en el pedido, constituyen el
            acuerdo entre las partes <b>("Acuerdo").</b>
          </span>
          <br />
          <br />
          <span>
            AL HACER CLIC EN EL BOTÓN “ACEPTAR” O AL UTILIZAR EL SITIO DE
            CUALQUIER OTRO MODO, USTED ACEPTA HABER LEÍDO Y ESTAR DE ACUERDO CON
            ESTOS TÉRMINOS Y CONDICIONES (UCA + TÉRMINOS COMPLEMENTARIOS).
            <br />
            <br />
            SI NO ESTÁ DE ACUERDO CON ESTAS CONDICIONES DE USO, NO HAGA CLIC EN
            EL BOTÓN “ACEPTAR”.
          </span>
          <br />
          <br />
          <ol>
            <li>
            <h5>DEFINICIONES</h5>
            <br />
            “Usuario autorizado” se refiere a cualquier empleado de Siemens y/o
            de las empresas afiliadas a Siemens y/o al cliente que cree un ID
            individual para el uso de la RSCT.
            <br />
            <br />
            “Cliente” se refiere a un cliente directo o indirecto, interno o
            externo de Siemens o de cualquier empresa afiliada a Siemens.
            <br />
            <br />
            “Descripción” se refiere a la descripción de la RSCT, incluida la
            finalidad para la que puede utilizarse, tal como se menciona en las
            instrucciones de servicio.
            <br />
            <br />
            “Pedido” se refiere, en el contexto del Acuerdo, al formulario de
            registro (i) en el que el Usuario autorizado introduce sus datos
            personales para crear una identificación, y (ii) que incorpora los
            Términos del presente Acuerdo, (iv) que ha sido acordado por el
            Cliente a través de un Usuario autorizado mediante un sistema
            electrónico especificado por Siemens, y (iii) que es aceptado por
            Siemens (tal y como se menciona en estos Términos RSCT), p. ej., al
            crear la identificación y conceder acceso a la RSCT.
            <br />
            <br />
            “Servicio” se refiere al suministro de la RSCT, tal y como se
            describe en las instrucciones de servicio.
            <br />
            <br />
            “Plazo de suscripción” se refiere al período de tiempo descrito en
            estos Términos RSCT durante el cual el servicio se pone a
            disposición del Cliente.
            <br />
            <br />
            “Territorio” se refiere a los países en los que será posible acceder
            a la herramienta.
            <br />
            <br />
            </li>
         
          <li>
            <h5 class="title1">DISPOSICIONES GENERALES</h5>
            <ol class="bullet">
              <li>
                 <b>Acceso y uso autorizados</b> Sólo los usuarios
                autorizados en el Territorio pueden acceder a la RSCT y
                utilizarla, únicamente de conformidad con el presente Acuerdo.
              </li>
              <li>
                 <b>Web/Móvil. </b>En la medida en que esta aplicación sea
                utilizada por los empleados de Siemens de EE. UU., sólo se podrá
                acceder a ella a través de dispositivos propiedad de Siemens.
              </li>
              <li>
                 <b>Oferta sin cargos.</b>La RSCT se ofrecerá a los Clientes
                sin cargo alguno.
              </li>
             
              <li>
                <b>Derechos de uso de la RSCT. </b> Siemens concede al
                Cliente un derecho no exclusivo, intransferible, no
                sublicenciable y limitado a acceder y utilizar la RSCT para
                fines empresariales del cliente, únicamente en virtud del
                presente Acuerdo y para los fines citados en la Descripción. El
                derecho a utilizar la RSCT no incluye el derecho a efectuar
                trabajos derivados o a incorporar el Servicio en productos o
                servicios propios o de terceros.
              </li>
              <li>
                <b>Cuenta/Derechos. </b> . El Cliente puede crear cuentas
                mediante la creación de una identificación de usuario (“ID”) por
                parte de los Usuarios autorizados (“Cuenta”). Una Cuenta permite
                al Cliente acceder al Servicio a través de Usuarios autorizados.
                Cada Usuario autorizado debe utilizar una identificación de
                usuario única para acceder al Servicio y utilizarlo. Los ID de
                los Usuarios autorizados no pueden compartirse con otras
                personas. La Cuenta será válida inicialmente por un período de
                seis (6) meses (“Plazo de suscripción”). La Cuenta se renueva
                automáticamente por el mismo periodo si el Usuario autorizado
                inicia sesión con su ID. Si el Usuario autorizado desea eliminar
                su ID, puede solicitar la eliminación tal y como se describe en
                el apartado 2.4 de las instrucciones de servicio
                correspondientes. El Cliente se asegurará de que todos los
                Usuarios autorizados cumplan con las obligaciones del Cliente en
                virtud del presente Acuerdo. El derecho de Siemens a modificar,
                limitar y suspender una Oferta sin cargos en cualquier momento y
                a su exclusiva discreción no se ve afectado. El Cliente se hace
                responsable de cualquier acto u abstención de cualquier Usuario
                o de cualquier persona que utilice o acceda a la Cuenta de un
                Usuario autorizado en relación con este Acuerdo.
              </li>
              <li>
                
                <b>Uso de la RSCT/Descargos de responsabilidad.</b>
                Se advierte al Cliente y éste acepta que los valores deben
                introducirse cuidadosamente en la RSCT, ya que la RSCT muestra
                los resultados y los cálculos con base en los valores
                introducidos. El resultado de la RSCT no es vinculante y no
                pretende ser completo en cuanto a la integridad y la idoneidad
                técnica de los resultados calculados. La RSCT sólo busca ofrecer
                una primera visión general de los productos recomendados. El
                Cliente es responsable de garantizar que los productos descritos
                se utilicen correctamente y sigue siendo siempre responsable en
                último término de la seguridad y el cumplimiento. Esta RSCT no
                exime al Cliente de la responsabilidad de instalar, utilizar,
                operar y mantener el equipo de forma segura y profesional. Si
                hubiera divergencias entre las recomendaciones dadas en la RSCT
                y otras publicaciones de Siemens, p. ej. catálogos pero sin
                limitarse a estos, entonces tienen prioridad los contenidos de
                las otras publicaciones de Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">DATOS y CONTENIDO DEL CLIENTE</h5>
            <ol class="bullet">
              <li>
                <b>Aviso de privacidad de datos. </b>Ni el Cliente ni ningún
                Usuario autorizado están autorizados a cargar y almacenar datos
                personales de terceros en la plataforma. El tratamiento de los
                datos personales del Cliente se rige por el Aviso de Privacidad
                de datos de Siemens disponible en:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Retención de datos.</b>
                <ul>
                  <li>
                    <b>Datos personales. </b> El tratamiento de los datos
                    personales se limita a la función de gestión de los usuarios
                    en el marco de la RSCT. La Cuenta de usuario y el ID
                    asociado que ya no estén activos se eliminarán sobre la base
                    de un inventario semestral de Usuarios y/o a petición
                    individual del Usuario, y los respectivos resultados de los
                    cálculos se archivarán de forma anónima.
                  </li>
                  <li>
                    <b>Contenido del Cliente. </b> El Contenido del cliente
                    también será almacenado/archivado por Siemens, sus empresas
                    afiliadas o subcontratistas, y podrá ser utilizado como se
                    describe en las instrucciones de servicio. Durante el
                    proceso de eliminación de los datos personales, los
                    respectivos resultados de los cálculos serán archivados de
                    forma anónima por Siemens. Los resultados de los cálculos
                    proporcionados por el subcontratista Labom se almacenarán
                    allí de forma anónima sin relación con los datos personales.
                  </li>
                </ul>
              </li>
              <li>
                 <b>Ubicación del centro de datos. </b> El centro de datos de
                la RSCT correspondiente a Siemens y su subcontratista Labom está
                ubicado en Alemania.
              </li>
              <li>
                <b>Uso de Contenido del Cliente. </b> Durante y después del
                Plazo de suscripción, Siemens, sus empresas afiliadas, sus
                partners comerciales y sus subcontratistas podrán utilizar y
                publicar el Contenido del Cliente para cualquier fin, incluso
                más allá de la prestación del Servicio, de acuerdo con las
                restricciones obligatorias impuestas por la legislación
                aplicable. El uso del Contenido más allá de la prestación del
                Servicio se efectuará, de acuerdo con esta sección, bajo la
                responsabilidad de Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>CONTACTO EN SIEMENS </h5>
            <br />
            Customer Support
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/ww/es/sc/4868"
              target="_blank"
              >https://support.industry.siemens.com/cs/ww/es/sc/4868</a
            >
          </li>
          </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
        
        <button   [attr.aria-label]="'TID_IAgree' | translate" id="operating-instruction-ack" type="button" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" id="operating-instruction-cancel" type="button" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>


<!-- //Bangkok// -->
<div
  cdkTrapFocus
  [attr.aria-modal]="true"
  role="dialog"
  class="modal-fade"
  *ngIf="isBangkok" aria-label="Remote Seal Calculation Tool Supplemental Terms"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="terms-header">
        <h4 id="terms-header" class="modal-title">
          Remote Seal Calculation Tool Supplemental Terms
        </h4>
      </div>
      <div class="modal-body">
        <br>
        English / English
        <br>
        <span>
          The purpose of this Remote Seal Calculation Tool is to assist the
          user/customer through the following services:
          <ul>
            <li>
              Provision of a web-based user interface for the user/customers to
              calculate a remote seal system based on specific parameters
              depending on the individual use case.
            </li>
            <li>
              Calculation of temperature errors and response time for the remote
              seal system.
            </li>
            <li>
              Storage of the input parameters and the calculation results with
              an unique ID within the Siemens data base, which can be used later
              on by the user / customer for ordering and production.
            </li>
          </ul>
          <br />
          <span>
            The Remote Seal Calculation Tool is a web-based application that
            enables Siemens employees and customers to find the right hardware.
          </span>
          <span>
            These Remote Seal Calculation Tool Supplemental Terms
            <b>(“RSCT Terms”)</b> amend the Universal Customer Agreement
            <b>(“UCA”)</b> between Customer and Siemens solely with regard to
            the use of the Remote Seal Calculation Tool (“RSCT”) as described in
            the respective operating instructions. These RSCT Terms together
            with the UCA and other applicable Supplemental Terms mentioned in
            these documents form the agreement between the parties
            <b>(“Agreement”)</b>.
          </span>
          <br />
          <br />
          <span>
            BY CLICKING THE “ACCEPT” BUTTON OR OTHERWISE USING THE SITE, YOU
            AGREE THAT YOU HAVE READ, AND AGREE TO COMPLY WITH AND BE BOUND BY
            THESE TERMS AND CONDITIONS (UCA + SUPPLEMENTAL TERMS).
            <br />
            <br />
            IF YOU DO NOT AGREE TO BE BOUND BY THESE USER TERMS, DO NOT CLICK
            THE “ACCEPT” BUTTON.
          </span>
          <br />
          <br />
          <ol>
          <li>
            <h5>DEFINITIONS</h5>
            “Authorized User” means any employee of Siemens and/or the Customer
            who creates an individual ID for the use of the RSCT.
            <br />
            <br />
            “Customer” means a direct or indirect, internal, or external
            customer of Siemens or any affiliated company of Siemens.
            <br />
            <br />
            “Description” means the description of the RSCT including the
            purpose for which it may be used as mentioned in the attached
            operating instructions.
            <br />
            <br />
            “Order” means the registration form (i) where the Authorized User
            enters its personal data to create an identification, (ii) which is
            marked as “Order” and (iii) that incorporates the terms of this
            Agreement, (iv) that has been agreed by Customer through an
            Authorized User by an electronic system specified by Siemens, and
            (iii) is accepted by Siemens (as mentioned in these RSCT Terms)
            e.g., by creating the identification and granting access to the
            RSCT.
            <br />
            <br />
            “Service” means the provision of the RSCT as described in the
            operating instructions.
            <br />
            <br />
            “Territory” means the global roll-out for worldwide use.
            <br />
            <br />
          </li>
          <li>
            <h5 class="title1">GENERAL</h5>
            <ol class="bullet">
              <li>
                <b>Authorized Access and Use.</b> The RSCT may be accessed and
                used only by Authorized Users in the Territory, solely in
                accordance with this Agreement.
              </li>
              <li>
                <b>Web/Mobile.</b> To the extent this application is used by US
                Siemens employees, it may only be accessed via Siemens owned
                devices.
              </li>
              <li>
                <b>No-Charge Offering.</b> The RSCT will be offered at no-charge
                to Customers.
              </li>
              <li>
                <b>Use Rights for the RSCT.</b> Siemens grants Customer a
                nonexclusive, nontransferable, limited right to access and use
                the RSCT for Customer’s business purposes, solely in accordance
                with this Agreement and for the purpose as described in the
                Description. The right to use the RSCT shall not include the
                right to make derivative work or to include the Service into
                Customer own- or third-party products or services.
              </li>
              <li>
                <b>Account/Entitlement.</b> Customer may create accounts through
                the creation of user identification (“ID”) by Authorized Users
                (“Account”). An Account allows Customer to access the Service
                via Authorized Users. Each Authorized User must use a unique
                user identification to access and use the Service. Authorized
                User IDs may not be shared with other individuals. The Account
                will initially be valid for a period of six months. The Account
                automatically renews after the initial for the same period if
                the Authorized User logs in with its ID. If the Authorized User
                wishes to delete its ID, the Authorized User may request the
                deletion as described in the under 2.3 attached operating
                instruction. Customer will ensure that all Authorized Users
                comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
              <li>
                <b>Use of the RSCT/Disclaimer. </b> Customer may create accounts
                through the creation of user identification (“ID”) by Authorized
                Users (“Account”). An Account allows Customer to access the
                Service via Authorized Users. Each Authorized User must use a
                unique user identification to access and use the Service.
                Authorized User IDs may not be shared with other individuals.
                The Account will initially be valid for a period of six months.
                The Account automatically renews after the initial for the same
                period if the Authorized User logs in with its ID. If the
                Authorized User wishes to delete its ID, the Authorized User may
                request the deletion as described in the under 2.3 attached
                operating instruction. Customer will ensure that all Authorized
                Users comply with Customer’s obligations under this Agreement.
                Siemens’ right to change, limit, and discontinue a No-Charge
                Offering at any time in Siemens’ sole discretion remains
                unaffected. Customer is responsible for any act or failure to
                act by any User or any person using or accessing the account of
                an Authorized User in connection with this Agreement.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2"> DATA and CUSTOMER CONTENT</h5>
            <ol class="bullet">
              <li>
                <b> Data Privacy Notice.</b>Neither Customer nor any Authorized
                User is permitted to upload and store personal data of third
                parties on the Platform. The processing of Customer’s personal
                data is governed by the Siemens Privacy Notice available at:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Data Retention.</b>
                <ol>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Personal Data.</b> The processing of personal data is
                    limited to the user management functionality within the
                    RSCT. User account and the associated ID which is no longer
                    active will be deleted on the basis of a semi-annual user
                    inventory and/or at the individual request of the user and
                    the respective calculation results are archived anonymously.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Customer Content. </b> Customer content will be also
                    stored / archived by Siemens, its affiliates companies or
                    subcontractors, and may be used as described in the
                    operating instructions. During the deletion process of
                    personal data the respective calculation results will be
                    archived anonymously by Siemens.
                    <br />
                    The calculation results provided by the subcontractor Labom
                    are stored there anonymously per default without
                    relationship to personal data.
                  </li>
                </ol>
              </li>
              <li>
                <b>Data Center Locations.</b> Data Center of the RSCT is located
                in Germany.
              </li>
              <li>
                <b>Use of Customer Content. </b> During and after the
                Subscription Term, Siemens, its affiliated companies, its
                business partners and subcontractors may use and publish
                Customer Content for any purpose including beyond the
                performance of the Service, in accordance with mandatory
                restrictions imposed by applicable law. Use of Content beyond
                the performance of the Service in accordance with this section
                will be at Siemens risk.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5> SIEMENS CONTACT</h5>
            Customer Support
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW"
              target="_blank"
              >https://support.industry.siemens.com/cs/sc/4868/technical-support?lc=en-WW</a
            >
          </li>
        </ol>
        </span>

        <br>
        Indonesian / Bahasa Indonesia
        <br>
        <span>
          Tujuan Remote Seal Calculation Tool (Alat Penghitungan Segel Jarak
          Jauh) ini adalah untuk membantu pengguna/pelanggan melalui layanan
          berikut:
          <ul>
            <li>
              Penyediaan antarmuka pengguna berbasis web bagi pengguna/pelanggan
              untuk menghitung sistem segel jarak jauh berdasarkan parameter
              khusus tergantung pada masing-masing kasus penggunaan.
            </li>
            <li>
              Penghitungan kesalahan suhu dan waktu respons untuk waktu segel
              jarak jauh.
            </li>
            <li>
              Penyimpanan parameter input dan hasil penghitungan dengan ID unik
              dalam database Siemens, yang dapat digunakan nanti oleh pengguna /
              pelanggan untuk pemesanan dan produksi.
            </li>
          </ul>
          <br />
          <span>
            Remote Seal Calculation Tool (Alat Penghitungan Segel Jarak Jauh)
            adalah aplikasi berbasis web yang memungkinkan karyawan dan
            Pelanggan Siemens menemukan perangkat keras yang tepat.
          </span>
          <span>
            Ketentuan Tambahan Remote Seal Calculation Tool (Alat Penghitungan
            Segel Jarak Jauh) (“Ketentuan
            <b>RSCT</b>”) ini mengubah Perjanjian Pelanggan Universal ("
            <b>UCA</b>”/Universal Customer Agreement) antara Pelanggan dan
            Siemens hanya dalam kaitannya dengan penggunaan Alat Penghitungan
            Segel Jarak Jauh (“RSCT”/Remote Seal Calculation Tool) sebagaimana
            dijelaskan dalam masing-masing petunjuk pengoperasian. Ketentuan
            RSCT ini bersama dengan UCA dan Ketentuan Tambahan yang berlaku
            lainnya sebagaimana disebutkan dalam dokumen ini dan/atau formulir
            Pemesanan, perjanjian antar pihak-pihak <b>("Perjanjian")</b>.
          </span>
          <br />
          <br />
          <span>
            DENGAN MENGKLIK TOMBOL “ACCEPT” (TERIMA) ATAU MENGGUNAKAN SITUS INI,
            ANDA MENYETUJUI BAHWA ANDA TELAH MEMBACA, DAN MENYETUJUI UNTUK PATUH
            DAN TERIKAT OLEH KETENTUAN DAN SYARAT INI (UCA + KETENTUAN
            TAMBAHAN).
            <br />
            <br />
            JIKA ANDA TIDAK MENYETUJUI UNTUK TERIKAT OLEH KETENTUAN PENGGUNA
            INI, JANGAN MENGKLIK TOMBOL “ACCEPT” (TERIMA).
          </span>
          <br />
          <br />
          <ol>
            <li>
            <h5>DEFINISI</h5>
            “Pengguna Resmi” berarti karyawan Siemens dan/atau
            perusahaan-perusahaan yang berafiliasi dengan Siemens dan/atau
            Pelanggan yang membuat ID individu untuk penggunaan RSCT.
            <br />
            <br />
            “Pelanggan” berarti pelanggan internal atau eksternal Siemens atau
            salah satu perusahaan yang berafiliasi dengan Siemens baik secara
            langsung atau tidak langsung.
            <br />
            <br />
            “Deskripsi” berarti deskripsi RSCT, termasuk tujuan penggunaannya
            sebagaimana disebutkan dalam petunjuk pengoperasian.
            <br />
            <br />
            “Pemesanan” berarti konteks Perjanjian, formulir pendaftaran (i)
            yang mana Pengguna Resmi memasukkan data pribadinya untuk membuat
            identifikasi, dan (ii) yang menyertakan ketentuan Perjanjian ini,
            (iv) yang telah disetujui oleh Pelanggan melalui Pengguna Resmi
            dengan sistem elektronik yang ditentukan oleh Siemens, dan (iii)
            diterima oleh Siemens (sebagaimana disebutkan dalam Ketentuan RSCT
            ini) misalnya, dengan membuat identifikasi dan memberikan akses
            kepada RSCT.
            <br />
            <br />
            “Layanan” berarti ketetapan RSCT sebagaimana dijelaskan dalam
            petunjuk pengoperasian.
            <br />
            <br />
            “Ketentuan Langganan” berarti periode waktu yang dijelaskan dalam
            Ketentuan RSCT yang mana Layanan tersedia bagi Pelanggan.
            <br />
            <br />
            “Wilayah” berarti negara tempat alat ini akan tersedia.
            <br />
            <br />
            </li>
     
          <li>
            <h5 class="title1">UMUM</h5>
            <ol class="bullet">
              <li>
                <b>Akses dan Penggunaan Resmi.</b> RSCT hanya bisa diakses dan
                digunakan oleh Pengguna Resmi di Wilayah, hanya sebagaimana yang
                dinyatakan dalam Perjanjian ini.
              </li>
              <li>
                <b>Web/Seluler.</b> Selama aplikasi ini digunakan oleh karyawan
                Siemens AS, aplikasi ini hanya bisa diakses melalui perangkat
                yang dimiliki Siemens.
              </li>
              <li>
                <b>Penawaran Tanpa Biaya.</b> RSCT akan ditawarkan tanpa biaya
                kepada Pelanggan.
              </li>
              
              <li>
                <b>Hak Penggunaan untuk RSCT. </b> Siemens memberikan hak
                terbatas yang bersifat non-eksklusif, tidak dapat dipindahkan,
                tidak dapat disublisensikan kepada pelanggan untuk mengakses dan
                menggunakan RSCT untuk tujuan bisnis pelanggan, hanya
                sebagaimana yang dinyatakan dalam Perjanjian ini dan untuk
                tujuan sebagaimana dijelaskan dalam Deskripsi. Hak untuk
                menggunakan RSCT tidak boleh menyertakan hak untuk membuat karya
                turunan atau menyertakan Layanan bagi produk atau layanan yang
                dimiliki Pelanggan atau pihak ketiga.
              </li>
              <li>
                <b>Akun/Hak. </b> Pelanggan bisa membuat akun melalui pembuatan
                identifikasi pengguna (“ID”) oleh Pengguna Resmi (“Akun”).
                Dengan sebuah Akun, Pelanggan dapat mengakses Layanan melalui
                Pengguna Resmi. Setiap Pengguna Resmi harus menggunakan
                identifikasi pengguna unik untuk mengakses dan menggunakan
                Layanan. ID Pengguna Resmi tidak boleh dibagikan dengan individu
                lain. Akun pada awalnya akan berlaku selama periode enam (6)
                bulan (“Ketentuan Langganan”). Akun diperbarui secara otomatis
                dalam periode yang sama jika Pengguna Resmi login dengan ID yang
                dimilikinya. Jika Pengguna Resmi ingin menghapus ID miliknya,
                Pengguna Resmi bisa meminta penghapusan sebagaimana dijelaskan
                di bagian 2.4 merujuk pada petunjuk pengoperasian. Pelanggan
                akan memastikan bahwa semua Pengguna Resmi mematuhi kewajiban
                Pelanggan menurut Perjanjian ini. Hak Siemens untuk mengubah,
                membatasi, dan menghentikan Penawaran Tanpa Biaya kapan saja
                berdasarkan pertimbangan mutlak Siemens tetap tidak terdampak.
                Pelanggan bertanggung jawab atas tindakan apa pun atau kegagalan
                bertindak oleh Pengguna atau siapa saja yang menggunakan atau
                mengakses akun Pengguna Resmi dalam kaitannya dengan Perjanjian
                ini.
              </li>
              <li>
                <b>Penggunaan RSCT/Penafian. </b> Pelanggan disarankan dan
                menyetujui bahwa nilai-nilai harus dimasukkan secara saksama ke
                dalam RSCT, karena RSCT menampilkan hasil dan penghitungan
                berdasarkan nilai-nilai yang dimasukkan. Hasil RSCT tidak
                bersifat mengikat dan tidak diklaim sebagai hasil lengkap dalam
                kaitannya dengan kelengkapan dan kebenaran teknis hasil yang
                dihitung. RSCT hanya ditujukan untuk menyediakan ringkasan
                pertama yang mana produk direkomendasikan. Pelanggan bertanggung
                jawab dalam memastikan bahwa produk yang dijelaskan digunakan
                dengan benar dan tetap selalu bertanggung jawab atas keselamatan
                dan kepatuhannya. RSCT ini tidak membebaskan Pelanggan dari
                tanggung jawab dalam penggunaan, pemasangan, pengoperasian, dan
                servis peralatan secara aman dan profesional. Jika ada
                penyimpangan antara rekomendasi yang diberikan dalam RSCT dan
                publikasi Siemens lainnya - misalnya, tapi tidak terbatas pada
                katalog - maka isi publikasi Siemens lainnya diprioritaskan.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5 class="title2">ISI DATA dan PELANGGAN</h5>
            <ol class="bullet">
              <li>
                <b>Pemberitahuan Privasi Data.</b>Tidak satu pun dari Pelanggan
                atau Pelanggan Resmi yang diizinkan untuk mengunggah dan
                menyimpan data pribadi pihak ketiga ke dalam Platform ini.
                Pemrosesan data pribadi Pelanggan yang dikelola menurut
                Pemberitahuan Privasi Siemens tersedia di:
                <a class="link-primary" href="https://www.siemens.com/privacy" target="_blank"
                  >https://www.siemens.com/privacy</a
                >
              </li>
              <li>
                <b>Penyimpanan Data.</b>
                <ol>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Data Pribadi. </b> Pemrosesan data pribadi dibatasi pada
                    fungsionalitas manajemen pengguna dalam RSCT. Akun pengguna
                    dan ID terkait yang tidak lagi aktif akan dihapus
                    berdasarkan inventaris pengguna setengah tahunan dan/atau
                    permintaan individu pengguna dan hasil penghitungan
                    masing-masing diarsipkan secara anonim.
                  </li>
                  <li class="s3sub" style="list-style-type: square;">
                    <b>Konten Pelanggan. </b> Konten pelanggan juga akan
                    disimpan/diarsipkan oleh Siemens, perusahaan afiliasinya
                    atau subkontraktornya, serta bisa digunakan sebagaimana
                    dijelaskan dalam petunjuk pengoperasian. Selama proses
                    penghapusan data pribadi, hasil penghitungan masing-masing
                    akan diarsipkan secara anonim oleh Siemens. Hasil
                    penghitungan disediakan oleh subkontraktor Labom dan
                    disimpan di sana secara anonim tanpa hubungan dengan data
                    pribadi.
                  </li>
                </ol>
              </li>
              <li>
                <b>Lokasi Pusat Data. </b> Pusat Data RSCT terletak di Jerman
                untuk Siemens dan subkontraktornya, Labom.
              </li>
              <li>
                <b>Penggunaan Konten Pelanggan. </b> Selama dan setelah
                Ketentuan Langganan, Siemens, perusahaan afiliasinya, mitra
                bisnis dan subkontraktornya bisa menggunakan dan menerbitkan
                Konten Pelanggan untuk tujuan apa pun termasuk di luar kinerja
                Layanan, sesuai dengan pembatasan wajib yang diterapkan oleh
                hukum yang berlaku. Penggunaan Konten di luar kinerja Layanan
                sesuai dengan bagian ini akan menjadi risiko Siemens.
              </li>
            </ol>
            <br />
          </li>
          <li>
            <h5>KONTAK SIEMENS</h5>
            Dukungan Pelanggan
            <br />
            <a class="link-primary"
              href="https://support.industry.siemens.com/cs/ww/en/sc/4868"
              target="_blank"
              >https://support.industry.siemens.com/cs/ww/en/sc/4868</a
            >
          </li>
          </ol>
        </span>
      </div>
      <div class="modal-footer" style="flex-flow: nowrap;">
        
        <button   [attr.aria-label]="'TID_IAgree' | translate" id="operating-instruction-ack" type="button" class="btn btn-primary" (click)="accept()">
          {{'TID_IAgree' | translate}}
        </button>
        <button [attr.aria-label]="'TID_Cancel' | translate" id="operating-instruction-cancel" type="button" class="btn btn-secondary" (click)="close()">
          {{'TID_Cancel' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>