<div
  tabindex="-1"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  [attr.aria-modal]="true"
  role="dialog" attr.aria-label="{{'TID_Disclaimer'  | translate}}"
>
  <div class="modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header" aria-labelledby="disclaimer">
        <h4 id="disclaimer" class="modal-title">
          {{ 'TID_Disclaimer' | translate }}
        </h4>
        <div>
          <button id="hide-disclaimer-modal"
            [attr.aria-label]="'TID_Close' | translate"
            class="siemens-close pull-right btn1"
            id="close"
            (click)="hideModal(1)"
          ></button>
        </div>
      </div>
      <div class="modal-body div1">
        <span *ngIf="this.display">
          <p>
            The user of the RSCT is hereby expressly advised to enter the values
            carefully, as the RSCT displays results based on the user's entries.
          </p>
          <p>
            The result of the RSCT is not binding and does not claim to be
            complete regarding the completeness and technical correctness of the
            calculated results. The RSCT is only intended to provide a first
            overview which products are recommended.
          </p>
          <p>
            The user of this tool is responsible in ensuring that the described
            products are correctly used and always remains ultimately
            responsible for the safety and compliance. This RSCT does not
            relieve the user of the tool of the responsibility in safely and
            professionally using, installing, operating and servicing equipment.
            When using this RSCT, the user of the tool recognizes that Siemens
            cannot be made liable for any damage/claims beyond the liability
            clause described. Siemens reserves the right to make changes to this
            RSCT at any time without prior notice. If there are any deviations
            between the recommendations provided in RSCT and other Siemens
            publications - e.g. Catalogs - then the contents of the other
            documents have priority.
          </p>
          <p>
            <b>
              Warranty and Liability
            </b>
            <br />
            <br />
            Siemens does not accept any liability for the information provided
            by the RSCT.
          </p>
          <p>
            Any claims against Siemens - based on whatever legal reason -
            resulting from the use of the calculated results and product
            recommendations described in the RSCT shall be excluded. Such an
            exclusion shall not apply in the case of mandatory liability, e.g.
            under the German Product Liability Act (“Produkthaftungsgesetz”), in
            case of intent, gross negligence, or injury of life, body or health,
            guarantee for the quality of a product, fraudulent concealment of a
            deficiency or breach of a fundamental condition of the contract
            (“wesentliche Vertragspflichten”). However, claims arising from a
            breach of a fundamental condition of the contract shall be limited
            to the foreseeable damage which is intrinsic to the contract, unless
            caused by intent or gross negligence or based on mandatory liability
            for injury of life, body or health. The above provisions do not
            imply a change in the burden of proof to detriment of the user of
            this RSCT.
          </p>
          <p>
            This disclaimer applies in addition to the Terms of Use.
            <br />
            <a
              class="a3"
              target="_blank"
              rel="noopener"
              href="https://new.siemens.com/global/en/general/terms-of-use.html"
              >https://new.siemens.com/global/en/general/terms-of-use.html</a
            >
          </p>
          <p>
            In the event of any discrepancy between the provisions of the Terms
            of Use and this disclaimer, this disclaimer shall prevail.
          </p>
          <p>
            <b>China Clause</b>
            <br />
            <br />
            China Cyber Security Law, China Data Security Law and China Personal
            Information Protection Law have special requirements with regard to
            the cross-border transmission of sensitive data, important data as
            well as personal data. This tool will not request any sensitive
            data, important data or sensitive personal data defined under China
            Cyber Security Law, China Data Security Law and China Personal
            Information Protection Law. For Chinese users, please note that all
            data entered into this tool will be stored outside of China. If you
            have any questions or concerns, please contact the project team.
          </p>
        </span>
        <span *ngIf="!this.display">
          <p>
            Der Nutzer des RSCT wird hiermit ausdrücklich darauf hingewiesen,
            die Werte sorgsam einzutragen, da das RSCT Ergebnisse anhand der
            Eingaben des Nutzers anzeigt.
          </p>
          <p>
            Das Ergebnis des RSCT ist unverbindlich und erhebt keinen Anspruch
            auf Vollständigkeit und technische Korrektheit der
            Kalkulations-Ergebnisse. Das RSCT dient lediglich dazu, einen ersten
            Überblick zu geben, welche Produkte empfohlen werden.
          </p>
          <p>
            Der Anwender dieses Tools trägt die Verantwortung für den korrekten
            Einsatz der beschriebenen Produkte und bleibt letztlich immer für
            die Sicherheit und Konformität verantwortlich. Dieses RSCT entbindet
            den Anwender des Tools nicht von der Verantwortung für die sichere
            und fachgerechte Verwendung, Installation, Bedienung und Wartung der
            Geräte. Mit der Nutzung des RSCT erkennt der Nutzer des Tools an,
            dass Siemens über die beschriebene Haftungsklausel hinaus für
            keinerlei Schäden/Ansprüche haftbar gemacht werden kann. Siemens
            behält sich das Recht vor, jederzeit und ohne vorherige Ankündigung
            Änderungen an diesem RSCT vorzunehmen. Bei Abweichungen zwischen den
            im RSCT gegebenen
          </p>
          <p>
            <b>
              Gewährleistung und Haftung
            </b>
            <br />
            <br />
            Siemens übernimmt keine Haftung für die durch das RSCT
            bereitgestellten Informationen.
          </p>
          <p>
            Jegliche Ansprüche gegen Siemens - gleich aus welchem Rechtsgrund -,
            die sich aus der Verwendung der im RSCT beschriebenen
            Kalkulationsergebnisse und Produktempfehlungen ergeben, sind
            ausgeschlossen. Dieser Ausschluss gilt nicht, soweit zwingend
            gehaftet wird, z.B. nach dem Produkthaftungsgesetz, in Fällen des
            Vorsatzes, der groben Fahrlässigkeit, wegen der Verletzung des
            Lebens, des Körpers oder der Gesundheit, wegen der Übernahme einer
            Garantie für die Beschaffenheit des Produktes, wegen arglistigen
            Verschweigens eines Mangels oder wegen der Verletzung wesentlicher
            Vertragspflichten. Der Schadensersatzanspruch für die Verletzung
            wesentlicher Vertragspflichten ist jedoch auf den vertragstypischen,
            vorhersehbaren Schaden begrenzt, soweit nicht Vorsatz oder grobe
            Fahrlässigkeit vorliegt oder wegen der Verletzung des Lebens, des
            Körpers oder der Gesundheit zwingend gehaftet wird. Eine Änderung
            der Beweislast zum Nachteil des Nutzers dieses RSCT ist mit den
            vorstehenden Regelungen nicht verbunden.
          </p>
          <p>
            Dieser Disclaimer gilt in Ergänzung zu den Nutzungsbedingungen.
            <br />
            <a
              class="a3"
              target="_blank"
              rel="noopener"
              href="https://new.siemens.com/global/de/general/nutzungsbedingungen.html"
              >https://new.siemens.com/global/de/general/nutzungsbedingungen.html</a
            >
          </p>
          <p>
            Bei Abweichungen zwischen den Regelungen in den Nutzungsbedingungen
            und dem Disclaimer, hat der Disclaimer Vorrang.
          </p>
          <p>
            <b>China-Klausel</b>
            <br />
            <br />
            Das chinesische Cybersicherheitsgesetz, das chinesische
            Datensicherheitsgesetz und das chinesische Gesetz zum Schutz
            persönlicher Informationen stellen besondere Anforderungen an die
            grenzüberschreitende Übermittlung von sensiblen Daten, wichtigen
            Daten und persönlichen Daten. Dieses Tool fragt keine sensiblen
            Daten, wichtigen Daten oder sensiblen personenbezogenen Daten ab,
            die unter dem chinesischen Cybersicherheitsgesetz, dem chinesischen
            Datensicherheitsgesetz und dem chinesischen Gesetz zum Schutz
            personenbezogener Daten definiert sind. Für chinesische Benutzer ist
            zu beachten, dass alle in dieses Tool eingegebenen Daten außerhalb
            Chinas gespeichert werden. Wenn Sie Fragen oder Bedenken haben,
            wenden Sie sich bitte an das Projektteam.
          </p>
        </span>
      </div>
    </div>
  </div>
</div>
