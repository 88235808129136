import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HandleErrorService } from './handle-error.service';

@Injectable()
export class HttpInterceptorErrors implements HttpInterceptor {
    constructor(private readonly error: HandleErrorService) {}
    // intercept function
    public intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return new Observable((observer) => {
            next.handle(req).subscribe(
                (res: HttpResponse<any>) => {
                    if (res instanceof HttpResponse) {
                        observer.next(res);
                    }
                },
                (err: HttpErrorResponse) => {
                    this.error.handleError(err);
                }
            );
        });
    }
}
