import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Injectable({
    providedIn: 'root',
})
export class NgxLoaderService {
    constructor(private readonly ngxUiLoaderService: NgxUiLoaderService) {}

    public presentLoading(): Promise<any> {
        return new Promise((resolvePresentLoader) => {
            resolvePresentLoader(this.ngxUiLoaderService.start());
        });
    }
    public dismissLoading(): Promise<any> {
        return new Promise((resolveDismissLoader) => {
            resolveDismissLoader(this.ngxUiLoaderService.stop());
        });
    }
}
