<div
  tabindex="-1"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  [attr.aria-modal]="true"
  role="dialog" attr.aria-label="{{'TID_Contact'  | translate}}"
>
  <div class="modal-header" aria-labelledby="cancel-modal">
    <h4 id="cancel-modal" class="modal-title">{{ 'TID_Cancel' | translate }}</h4>
  </div>
  <div class="modal-body">
    <p>
      {{ 'TID_EnteredDataWillBeLost' | translate }}{{
      'TID_AreYouSureYouWantToContinue' | translate }}
    </p>
  </div>
  <div class="modal-footer">
    <button id="yes-cancel-modal"
      type="button"
      class="btn btn-primary"
      (click)="routeToGivenRoute()"
      [attr.aria-label]="'TID_Yes' | translate"
    >
      {{ 'TID_Yes' | translate }}
    </button>
    <button id="no-cancel-modal"
      type="button"
      class="btn btn-secondary"
      (click)="hideModal($event)"
      [attr.aria-label]="'TID_No' | translate"
    >
      {{ 'TID_No' | translate }}
    </button>
  </div>
</div>
