import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from '../Modals/error-modal/error-modal.component';
import { NgxLoaderService } from './ngx-loader.service';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  zero=0;
  twoHundred=200;
  constructor(
    private readonly injector: Injector,
    private translate: TranslateService,
    private readonly zone: NgZone,
    private loader: NgxLoaderService
  ) {}

  public handleError(err: HttpErrorResponse) {
    this.zone.run(() => {
      const modalService: BsModalService = this.injector.get(BsModalService);
      const modalRef: ErrorModalComponent = modalService.show(
        ErrorModalComponent,
        { backdrop: 'static' }
      ).content;
      let errorMessage: number | string,
        errorMsg: object | string,
        errorUrl: string;
      if (err.error instanceof ErrorEvent) {
        // A client-side or network error occurred
        errorMessage = `An error occurred: ${err.error.message}`;
      } else {
        // The backend returned an unsuccessful response code.
        if (err.status === 403 && err.error === null) {
          errorMsg =
            this.translate.instant('TID_Inactive') +
            ' ' +
            this.translate.instant('TID_User');
          errorMessage = err.status;
        }
        else if (err.status === this.zero) {
          errorMsg = this.translate.instant('TID_TheApplicationHasAnUnknownError')+' '+this.translate.instant('TID_TryAgain');
        }
         else if (err.status !== this.twoHundred) {
          errorMsg = err.error;
          errorMessage = err.status;
          errorUrl = err.url;
        } else {
          if (typeof err.error === 'object') {
            errorMessage = err.status;
            errorUrl = err.url;
            errorMsg = err.error.text;
          }
        }
      }
      setTimeout(() => {
        modalRef.show('Error Dialog', errorMessage, errorUrl, errorMsg);
        this.loader.dismissLoading();
      });
    });
  }
}
