/*
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file BehaviorSujectIntialize provides behavior subjects.
 *
 *
*/

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class BehaviorSujectIntialize {
    private behaviorSubject = new BehaviorSubject(null);

    constructor() {}

    init(): void {}

    setSubject(subject: any) {
        this.behaviorSubject.next(subject);
    }

    getSubject(subject: any) {
        if (!this.behaviorSubject) {
            return;
        }
        return this.behaviorSubject;
    }

    getValue(){
      return this.behaviorSubject.value;
    }

    destroy(): void {
        this.behaviorSubject.next(null);
        this.behaviorSubject.complete();
        this.behaviorSubject = null;
    }

    create(): void {
        this.behaviorSubject = new BehaviorSubject(null);
    }
}
