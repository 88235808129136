<div
  *ngIf="active"
  tabindex="-1"
  cdkTrapFocus
  [cdkTrapFocusAutoCapture]="true"
  [attr.aria-modal]="true"
  role="dialog"  attr.aria-label="{{'TID_Error'  | translate}}"
>
  <div class="modal-header" aria-labelledby="erro-modal-header">
    <h4 id="erro-modal-header" class="modal-title" *ngIf="!this.displayHeaderAsMessage">
      {{ 'TID_Error' | translate }}
    </h4>
    <h4 id="erro-modal-header" class="modal-title" *ngIf="this.displayHeaderAsMessage">
      {{ 'TID_MPID_MESSAGE' | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
    <br />
    <p>{{ error }}</p>
  </div>
  <div class="modal-footer">
    <button id="error-ok-modal"
      type="button"
      class="btn btn-primary"
      (click)="close()"
      [attr.aria-label]="'TID_Ok' | translate"
    >
      {{ 'TID_Ok' | translate }}
    </button>
  </div>
</div>
