/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file RuntimeParserService service provides EDD JSON parameters.
 *
 *
*/

import { Injectable } from '@angular/core';
import {
    UDMRuntimeParser,
    dms_UIElementContainer,
    dms_ElementTypeEnum,
    dms_UIElementReference,
    dms_Parameter,
} from 'udm-json-parser/dist/index';
import remoteseal from '../../udm-json-files/REMOTE_SEAL_0012-optimized.json';

@Injectable({
    providedIn: 'root',
})
export class RuntimeParserService extends UDMRuntimeParser {
    constructor() {
        const stringJSON = JSON.stringify(remoteseal);
        super(stringJSON);
    }

    /**
     * Retrieves all parameters from a UIElementContainer or a
     * UIElementReference given their id
     */
    public retrieveParametersFromUIElementWithName(
        elementID: string
    ): Array<dms_Parameter> {
        const param = this.getEDDItem(
            elementID,
            dms_ElementTypeEnum.DMS_UIELEMENTCONTAINER
        );
        return this.retrieveParametersFromUIElement(param);
    }
    /**
     * Retrieves all parameters from a UIElementContainer or a
     * UIElementReference
     */
    public retrieveParametersFromUIElement(
        uiElement: dms_UIElementContainer | dms_UIElementReference
    ): Array<dms_Parameter> {
        let parameters = [];
        if (uiElement) {
            if (uiElement.type === dms_ElementTypeEnum.DMS_UIELEMENTREFERENCE) {
                const elementReference = uiElement as dms_UIElementReference;
                const parameter = elementReference?.reference;
                parameters.push(parameter);
            }

            if (uiElement.type === dms_ElementTypeEnum.DMS_UIELEMENTCONTAINER) {
                const subMember = uiElement as dms_UIElementContainer;
                for (const subItem of subMember?.member) {
                    parameters = parameters.concat(
                        this.retrieveParametersFromUIElement(subItem)
                    );
                }
            }
        }
        return parameters;
    }

    public async getEnumerationList(
        element: dms_Parameter,
        service: RuntimeParserService
    ) {
        return element.hasEnumerationList.ev(service.valueStorage.proxy);
    }

    public retrieveContainersFromUIElement(
        elementID: string
    ): Array<dms_UIElementContainer> {
        const param = this.getEDDItem(
            elementID,
            dms_ElementTypeEnum.DMS_UIELEMENTCONTAINER
        );
        let containers = [];
        if (param.type === dms_ElementTypeEnum.DMS_UIELEMENTCONTAINER) {
            const subMember = param as dms_UIElementContainer;
            containers = param.member;
        }
        return containers;
    }
}
