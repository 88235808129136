/**
 * Copyright (C) Siemens AG 2016-2023 ALL RIGHTS RESERVED. CONFIDENTIAL
 *
 * About File description :
 *
 * This file uses GlobalConstants,TurnDown values and diaphragmSealVersionsFor7MF084
 *
 */

export const GlobalConstants = {
    instrumentTypeAbs: 'Abs',
    instrumentTypeDiff: 'Diff',
    instrumentTypeLevel: 'Level',
    instrumentTypeRel: 'Rel',
    adminUser: 'Admin',
    expertUser: 'Expert',
    standardUser: 'Standard',
    salesUser: 'Sales',
    testAdminUser: 'Test-Admin',
    testStandardUser: 'Test-Standard',
    instrumentTypeAbsdiff: 'AbsDiff',
} as const;

export const TurnDown = {
    '7MF030_7MF040': {
        0.25: 30.12048193,
        1: 100,
        4: 100,
        16: 100,
        63: 100,
        160: 100,
        400: 100,
        700: 100,
    },
    '7MF032_7MF042': {
        0.25: 30.12048193,
        1.3: 30.23255814,
        5: 30.12048193,
        30: 30,
        160: 30.18867925,
        400: 30.07518797,
        700: 30.04291845,
    },
    '7MF036_7MF046': {
        0.06: 60,
        0.25: 100,
        0.6: 240,
        1.6: 30.18867925,
        5: 30.12048193,
        30: 100,
        160: 20,
    },
    '7MF035_7MF045': {
        0.25: 100,
        0.6: 100,
        1.6: 100,
        5: 100,
        30: 100,
        160: 100,
    },
    '7MF034_7MF044': {
        0.06: 60,
        0.25: 100,
        0.6: 100,
        1.6: 100,
        5: 100,
        30: 100,
        160: 20,
    },
    '7MF033_7MF043': {
        0.25: 30.12048193,
        1.3: 30.23255814,
        5: 30.12048193,
        30: 30,
        160: 20,
    },
};

export const SILTurnDown = {
    '7MF030_7MF040': {
        0.25: 5,
        1: 5,
        4: 5,
        16: 5,
        63: 5,
        160: 5,
        400: 5,
        700: 5,
    },
    '7MF032_7MF042': {
        0.25: 5,
        1.3: 5,
        5: 5,
        30: 5,
        160: 5,
        400: 5,
        700: 5,
    },
    '7MF036_7MF046': {
        0.06: null,
        0.25: 5,
        0.6: 5,
        1.6: 5,
        5: 5,
        30: 5,
        160: 5,
    },
    '7MF035_7MF045': {
        0.25: 5,
        0.6: 5,
        1.6: 5,
        5: 5,
        30: 5,
        160: null,
    },
    '7MF034_7MF044': {
        0.06: null,
        0.25: 5,
        0.6: 5,
        1.6: 5,
        5: 5,
        30: 5,
        160: 5,
    },
    '7MF033_7MF043': {
        0.25: 5,
        1.3: 5,
        5: 5,
        30: 5,
        160: 5,
    },
};

export const diaphragmSealVersionsFor7MF084 = [
    {
        id: 145,
        code: '7MF084.-0A1',
        description: 'DN15',
        category: 'EN 1092-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-0A2',
        description: 'DN20',
        category: 'EN 1092-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-0B3',
        description: 'DN25',
        category: 'EN 1092-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-1K1',
        description: '1/2 Zoll',
        category: 'ASME B16.5',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-1K2',
        description: '3/4 Zoll',
        category: 'ASME B16.5',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-1K3',
        description: '1 Zoll',
        category: 'ASME B16.5',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-3S1',
        description: 'G1/4"B',
        category: 'EN 837-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-3S2',
        description: 'G1/2"B',
        category: 'EN 837-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-3S3',
        description: 'G3/4"B',
        category: 'EN 837-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-3S4',
        description: 'G1"B',
        category: 'EN 837-1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T1',
        description: '¼"-NPT-M',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T2',
        description: '¼"-NPT-F',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T3',
        description: '1/2"-NPT-M',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T4',
        description: '1/2"-NPT-F',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T5',
        description: '3/4"-NPT-M',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T6',
        description: '3/4"-NPT-F',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T7',
        description: '1"-NPT-M',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    {
        id: 145,
        code: '7MF084.-5T8',
        description: '1"-NPT-F',
        category: 'ASME B1.20.1',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
];

export const nominalPressureMapping = {
    'EN 1092-1': {
        DN15: {
            'PN10/16/25/40': 'PN100 / class 1500',
            'PN 63/100': 'PN100 / class 1500',
            PN160: 'PN250 / class 3675',
            PN250: 'PN250 / class 3675',
        },
        DN20: { 'PN10/16/25/40': 'PN100 / class 1500' },
        DN25: {
            'PN10/16/25/40': 'PN100 / class 1500',
            'PN 63/100': 'PN100 / class 1500',
            PN160: 'PN250 / class 3675',
            PN250: 'PN250 / class 3675',
        },
    },
    'ASME B16.5': {
        '1/2 Zoll': {
            'class 150': 'PN100 / class 1500',
            'class 300': 'PN100 / class 1500',
            'class 600': 'PN100 / class 1500',
            'class 1500': 'PN100 / class 1500',
        },
        '3/4 Zoll': {
            'class 150': 'PN100 / class 1500',
            'class 300': 'PN100 / class 1500',
            'class 600': 'PN100 / class 1500',
            'class 1500': 'PN100 / class 1500',
        },
        '1 Zoll': {
            'class 150': 'PN100 / class 1500',
            'class 300': 'PN100 / class 1500',
            'class 600': 'PN100 / class 1500',
            'class 1500': 'PN100 / class 1500',
        },
    },
    'EN 837-1': {
        'G1/4"B': {
            PN100: 'PN100 / class 1500',
            PN250: 'PN250 / class 3675',
        },
        'G1/2"B': {
            PN100: 'PN100 / class 1500',
            PN250: 'PN250 / class 3675',
        },
        'G3/4"B': {
            PN100: 'PN100 / class 1500',
            PN250: 'PN250 / class 3675',
        },
        'G1"B': {
            PN100: 'PN100 / class 1500',
            PN250: 'PN250 / class 3675',
        },
    },
    'ASME B1.20.1': {
        '¼"-NPT-M': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '¼"-NPT-F': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '1/2"-NPT-M': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '1/2"-NPT-F': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '3/4"-NPT-M': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '3/4"-NPT-F': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '1"-NPT-M': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
        '1"-NPT-F': {
            'class 1500': 'PN100 / class 1500',
            'class 3675': 'PN250 / class 3675',
        },
    },
};

export const labomDiaphragmSealVersionsFor7MF084 = {
    'PN100 / class 1500': {
        id: 145,
        code: '7MF084.-...',
        description: 'PN100 / class 1500',
        category: '',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
    'PN250 / class 3675': {
        id: 987,
        code: '7MF084.-...',
        description: 'PN250 / class 3675',
        category: '',
        pMax: 1.7976931348623157e308,
        datasheetNo: '',
    },
};

export interface IResponseTime {
    t631: number;
    t632: number;
    t633: number;
}

export interface ILabomResult {
    mbarToPercentFactor: number;
    errors: {
        errorByProcessTemperature: number;
        errorByAmbientTemperature: number;
        errorByAmbientDelta: number;
        errorByProcessDelta: number;
    };
    responseTimes: {
        tauForT1: number;
        tauForT2: number;
        tauForT3: number;
    };
    utilization: {
        volume: number;
        endPressure: number;
        customerOffsetAdjustment: number;
        offsetAdjustment: number;
        spanAdjustment: number;
        gradientAdjustment: number;
        volumeMinus: number;
        endPressureMinus: number;
    };
}

export interface User
{
    createdDate: string;
    lastLoginDate: string;
    eMail: string;
    givenName: string;
    lastName: string;
    company: string;
    gid: string;
    activ: boolean;
    roleId: string;
    roleName?: string;
    $id?: string;
    country?: string;
    language?: string;
    lockedDate?: string;
    login?: string;
    sex?: string;
    userId?: number;
};