/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file is used to prompt the user incase of an error.
 *
 *
*/
import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent implements OnInit {
    active = false;
    displayHeaderAsMessage = false;
    title: string;
    message: string | number;
    error: object | string;
    url: string;
    private status = 200;
    constructor(private readonly modalService: BsModalService) {}

    ngOnInit(): void {}

    show(
        title: string,
        message: string | number,
        errorUrl: string,
        errorMsg: object | string
    ) {
        this.title = title;
        this.displayHeaderAsMessage = message === this.status ? true : false;
        this.message = message;
        this.error = errorMsg;
        this.url = errorUrl;
        this.active = true;
    }

    close() {
      this.modalService.hide();
    }
}
