/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file is used to prompt the user regarding the closing of an on-going calculation.
 *
 *
*/
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-cancel-modal',
    templateUrl: './cancel-modal.component.html',
    styleUrls: ['./cancel-modal.component.scss'],
})
export class CancelModalComponent implements OnInit {
  routeCheck = false;
  public languageChange = false;
  constructor(
    private readonly modalService: BsModalService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {}

  /**
   * To hide the modal as soon as yes or no option is selcted
   */
  public hideModal(event: boolean) {
    this.routeCheck = false;
    this.languageChange = false;
    this.modalService.hide();
  }

  /**
   * To route to home page if yes option is selected
   * */
  public routeToGivenRoute() {
    this.routeCheck = true;
    this.languageChange = true;
    this.modalService.hide();
  }
}
