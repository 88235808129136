/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file is used to display terms of use for the application.
 *
 *
*/
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UtilsService } from '../../services/utils.service';
import { NgxLoaderService } from 'src/app/services/ngx-loader.service';
import {cityAndcountryMappingObject} from 'src/app/common/city-country'
@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
})
export class TermsOfUseComponent implements OnInit, AfterViewInit {
    systemLanguage = 'en';
    germanLang='de';
    englishLang = 'en';
    SpanishLang = 'es';
    indonesianLang = 'id';
    frenchLang= 'fr';
    isMexico= false;
    isBangkok = false;
    constructor(
        private readonly modalService: BsModalService,
        private readonly authService: OAuthService,
        private readonly utilsService: UtilsService,
        private loader: NgxLoaderService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.isMexico= false;
        this.isBangkok=false;
        this.displayTermsOfUse();
    }

    close() {
        this.loader.presentLoading();
        this.modalService.hide();
        this.authService.logOut();
        this.loader.dismissLoading();
    }

    accept() {
        this.utilsService.acceptedTerms = true;
        sessionStorage.setItem(
            'terms_of_use',
            JSON.stringify(this.utilsService.acceptedTerms)
        );
        this.modalService.hide();
    }

    openHelp(pdfLocation: string) {
        window.open(pdfLocation, '_blank').focus();
    }


    displayTermsOfUse()
    {
      let country:string | null=null;
      if (Intl) {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneList = timeZone.split("/");
        const city = timeZoneList[timeZoneList.length - 1];
        country = cityAndcountryMappingObject[city];
        this.updateSystemlanguageToShowCountryCode(country);
      }
      if(country==null)
      {
        this.systemLanguage = sessionStorage.getItem('user_lang');;
      }
    }

     updateSystemlanguageToShowCountryCode(country: string| null) {
        if (country === 'France') {
            this.systemLanguage = this.frenchLang;
        }
        else if (country === 'Colombia') {
            this.systemLanguage = this.SpanishLang;
        }
        else if (country === 'Mexico') {
            this.isMexico = true;
            this.systemLanguage = this.englishLang;
        }
        else if (country === 'Bangkok') {
            this.isBangkok = true;
            this.systemLanguage = null;
        }
        else if (country === 'Morocco') {
            this.systemLanguage = this.frenchLang;
        }
        else if (country === 'Germany') {
            this.systemLanguage = this.germanLang;
        }
        else if (country === 'Spain') {
            this.systemLanguage = this.SpanishLang;
        }
        else if (country === 'Indonesia') {
            this.systemLanguage = this.indonesianLang;
        }
    }
}
