export class CalculatedResultData {
    maxmeasuringspan :string | null = null;
    capillaryUnit :string | null = null;
    fillingFluidCode :string | null = null;
    factor:number[] =[] ;
    text :string | null= null;
    unit :string | null = null;
    totalNormalTempError :number | null = null;
    totalNormalMRError :number | null = null;
    remoteSealTotalNormal :number | null = null;
    transmitterTotalNormal :number | null = null;
    remoteSealNormalMRError :number | null = null;
    transmitterNormalMRError :number | null = null;
    totalMaxTempError :number | null = null;
    totalMaxMRError :number | null = null;
    remoteSealTotalMax :number | null = null;
    transmitterTotalMax :number | null = null;
    remoteSealMaxMRError :number | null = null;
    transmitterMaxMRError :number | null = null;
    remoteSealAmbientNormalTempError :number | null = null;
    remoteSealAmbientMaxTempError :number | null = null;
    remoteSealAmbientNormalMR :number | null = null;
    remoteSealAmbientMaxMR :number | null = null;
    remoteSealProcessNormalTempError :number | null = null;
    remoteSealProcessMaxTempError :number | null = null;
    remoteSealProcessNormalMR :number | null = null;
    remoteSealProcessMaxMR :number | null = null;
    t631 :number | null = null;
    t632 :number | null = null;
    t633 :number | null = null;
    t901 :number | null = null;
    t902 :number | null = null;
    t903 :number | null = null;
    volume :number | null = null;
    endPressure :number | null = null;
    functionality :number | null = null;
    offset :number | null = null;
    customerOffset :number | null = null;
    span :number | null = null;
    gradient :number | null = null;
    calibration :number | null = null;
    temperatureError :number | null = null;
    overallSystem :number | null = null;
}

export class CalculationData {
    tProcessMin :number | null = null;
    tProcessNormal :number | null = null;
    tProcessMax :number | null = null;
    tAmbientMin :number | null = null;
    tAmbientNormal :number | null = null;
    tAmbientMax :number | null = null;
    instrumentCode :string | null = null;
    measuringSpanMin :number | null = null;
    measuringSpanMax :number | null = null;
    pNominal :number | null = null;
    maxSpan :number | null = null;
    tAmbientMinValidation :number | null = null;
    tAmbientMaxValidation :number | null = null;
    tProcessMinValidation :number | null = null;
    tProcessMaxValidation :number | null = null;
    maxStaticPressure :number | null = null;
    staticPressure :number | null = null;
    tReference = 25;
    remoteSealMLFBHighSide : string | null = null;
    remoteSealMLFBLowSide : string | null = null;
}

export class CalculationRequestData {
    instrument: Instrument;
    plusSide: RemoteSealPlusSide;
    minusSide : RemoteSealMinusSide | null = null;
    pMin = 0;
    pMax = 0;
    pMaxStatic :number| null = null;
    tAmbient1 = 0;
    tAmbient2 = 0;
    tAmbient3 = 0;
    productionTemperature :string | null = null;
    constructor() {
        this.instrument = new Instrument();
        this.plusSide = new RemoteSealPlusSide();
    }
}

 class Instrument {
    instrumentID = 0;
    instrumentVersionID = 0;
    instrumentConnectionID :number | null = null;
}

 class RemoteSealPlusSide {
    diaphragmSealID = 0;
    diaphragmSealVersionID = 0;
    diaphragmSealFunctionUnitID = 0;
    extensionLength = 0;
    fluidID = 0;
    capillary: Capillary;
    mountingElement1ID = 0;
    mountingElement2ID = 0;
    heightInstrumentOverDiaphragmSeal = 0;
    tAmbientMin = 0;
    tAmbientMax = 0;
    tProcessMin = 0;
    tProcessMax = 0;
    negativePressureService :string | null = null;
    constructor() {
        this.capillary = new Capillary();
    }
}

export class RemoteSealMinusSide {
    diaphragmSealID = 0;
    diaphragmSealVersionID = 0;
    diaphragmSealFunctionUnitID = 0;
    extensionLength = 0;
    fluidID = 0;
    capillary: Capillary;
    mountingElement1ID = 0;
    mountingElement2ID = 0;
    heightInstrumentOverDiaphragmSeal = 0;
    tAmbientMin = 0;
    tAmbientMax = 0;
    tProcessMin = 0;
    tProcessMax = 0;
    negativePressureService :string | null = null;
    constructor() {
        this.capillary = new Capillary();
    }
}

 class Capillary {
    length = 0;
    diameter = 0;
}

export class DataForCalculation {
    calculatedResultData: CalculatedResultData;
    calculationData: CalculationData;
    calculationRequest: CalculationRequestData;
    constructor() {
        this.calculatedResultData = new CalculatedResultData();
        this.calculationData = new CalculationData();
        this.calculationRequest = new CalculationRequestData();
    }
}
