/**
 * Copyright (C) Siemens AG 2022. All Rights Reserved. Confidential

 * About File description :
 *
 * This file ResultCalculationService service provides the
 * to save the data to backend.
 *
 *
*/

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    GlobalConstants,
    ILabomResult,
    IResponseTime,
} from '../common/global-constants';
import { DataStoreService } from './data-store.service';
import { UtilsService } from './utils.service';
import { RuntimeParserService } from './runtime-parser.service';
import { UnitHandlingService } from './unit-handling.service';
import { LabomResult, OptionType } from './load-data';

@Injectable({
    providedIn: 'root',
})
export class ResultCalculationService {
    public factor = 1;
    t631: number;
    t632: number;
    t633: number;
    t901: number;
    t902: number;
    t903: number;
    overallSystem: number;
    pdfText: string;
    endPressure: number;
    volume: number;
    offsetAdjustment: number;
    customerOffsetAdjustment: number;
    spanAdjustment: number;
    gradientAdjustment: number;
    functionality: number;
    calibration: number;
    totalNormalMR: number;
    remoteSealProcessMax: number;
    remoteSealAmbientMax: number;
    remoteSealMaxProcessMeasuringRange: number;
    remoteSealMaxAmbientMeasuringRange: number;
    remoteSealTotalMaxMeasuringRange: number;
    remoteSeaProcessNormal: number;
    remoteSealAmbientNormal: number;
    remoteSealProcessMeasuringRange: number;
    remoteSealAmbientMeasuringRange: number;
    remoteSealTotalNormalMeasuringRange: number;
    tk1: number;
    tk2: number;
    r: number;
    tempErr: number;
    transmitterAmbientNormalMR: number;
    transmitterAmbientNormalTempErr: number;
    numberOfDecimalPlaces = 100;
    threeDecimalPlaces = 1000;
    transmitterAmbientMaxMR: number;
    transmitterAmbientMaxTempErr: number;
    transmitterCharacteristicErrorMR: number = 0;
    transmitterCharacteristicErrorTempErr: number = 0;
    transmitterNormalTemperatureErrorMR: number = 0;
    transmitterNormalTemperatureErrorAbsolute: number = 0;
    transmitterMaximumTemperatureErrorMR: number = 0;
    transmitterMaximumTemperatureErrorAbsolute: number = 0;
    twentyEight=28;
    two=2;
    unit: string;
    remoteSealTotalNormal: number;
    remoteSealTotalMax: number;
    totalNormalTempError: number;
    totalMaxMR: number;
    totalMaxTempErr: number;
    temperatureError: number;
    capillaryLengthMLFBHighSide: OptionType;
    capillaryLengthMLFBLowSide: OptionType;
    extensionLengthMLFBHighSide: OptionType;
    extensionLengthMLFBLowSide: OptionType;
    functionalityLabel: string;
    calibrationLabel: string;
    temperatureErrorLabel: string;
    instrumentType: string = localStorage.getItem('InstrumentType');
    showOptimalCalculation = false;
    labomAPiResultForOptimalCalculation;
    temperatureUnit ='\xB0C';
    constructor(
        private readonly parserService: RuntimeParserService,
        private readonly dataStoreService: DataStoreService,
        private readonly translate: TranslateService,
        private readonly unitHandlingService: UnitHandlingService,
        public utilsService: UtilsService
    ) {}
    public resultData: LabomResult;
    public calculation = null;
    public instrumentCode = null;
    public maxmeasuringspan = null;

    /**
     * Set pressure transmitter data for calculation request
     */
    setInstrumentData() {
        const instrumentID = localStorage.getItem('TransmitterId');
        const InstrumentVersionID = localStorage.getItem('InstrumentVersionId');
        this.utilsService.dataForCalculation.calculationRequest.instrument.instrumentID =
            Number(instrumentID);
        this.utilsService.dataForCalculation.calculationRequest.instrument.instrumentVersionID =
            Number(InstrumentVersionID);
    }

    /**
     * Set Remote seal high side data for calculation request
     */
    setDiaphragmSealData() {
        const diaphragmIdHigh = localStorage.getItem('diaphragmId');
        const versionIdHigh = localStorage.getItem('versionId');
        const DiaphragmFunctionUnitIdHigh = localStorage.getItem(
            'DiaphragmFunctionUnitId'
        );
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealID =
            Number(diaphragmIdHigh);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealVersionID =
            Number(versionIdHigh);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.diaphragmSealFunctionUnitID =
            Number(DiaphragmFunctionUnitIdHigh);
    }

    /**
     * Set Remote seal low side data for calculation request
     * @param diaphragmIdLow : Remote seal type low side id
     * @param versionIdLow: Remote seal standard low side id
     * @param DiaphragmFunctionUnitIdLow: Diaphragm material low side id
     */
    setDiaphragmSealDataMinusSide(
        diaphragmIdLow: string,
        versionIdLow: string,
        DiaphragmFunctionUnitIdLow: string
    ) {
        if (diaphragmIdLow && versionIdLow && DiaphragmFunctionUnitIdLow) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide =
                {
                    diaphragmSealID: 0,
                    diaphragmSealVersionID: 0,
                    diaphragmSealFunctionUnitID: 0,
                    extensionLength: 0,
                    fluidID: 0,
                    capillary: {
                        length: 0,
                        diameter: 0,
                    },
                    mountingElement1ID: 0,
                    mountingElement2ID: 0,
                    heightInstrumentOverDiaphragmSeal: 0,
                    tAmbientMin: 0,
                    tAmbientMax: 0,
                    tProcessMin: 0,
                    tProcessMax: 0,
                    negativePressureService: null,
                };
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealID =
                Number(diaphragmIdLow);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealVersionID =
                Number(versionIdLow);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.diaphragmSealFunctionUnitID =
                Number(DiaphragmFunctionUnitIdLow);
        }
    }

    /**
     * Set mounting high side data for calculation request
     */
    setMountingData() {
        const fillingFluidId = localStorage.getItem('FillingFluid');
        this.utilsService.dataForCalculation.calculationRequest.plusSide.fluidID =
            Number(fillingFluidId);
        const mountingElemntID = localStorage.getItem('MountingID');
        this.utilsService.dataForCalculation.calculationRequest.plusSide.mountingElement1ID =
            Number(mountingElemntID);
    }

    /**
     * Set mounting low side data for calculation request
     * @param mountingElemntID : Moutning type low side id
     * @param length: capillary length for low side
     * @param height: height difference of low side
     * @param diameter: inner diameter for low side
     */
    setMountingDataMinusSide(
        mountingElemntID: string,
        length: number,
        height: number,
        diameter: number
    ) {
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            const fillingFluidId = localStorage.getItem('FillingFluid');
            this.utilsService.dataForCalculation.calculationRequest.minusSide.fluidID =
                Number(fillingFluidId);
            this.utilsService.dataForCalculation.calculationRequest.minusSide.mountingElement1ID =
                Number(mountingElemntID);
            if (length) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.length =
                    length;
                this.utilsService.configurationData.minusSide.capillary.length =
                    length;
            }
            if (diameter) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter =
                    Number(diameter);
                this.utilsService.configurationData.minusSide.capillary.diameter =
                    Number(diameter);
            } else if (length) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter = 1.3;
                this.utilsService.configurationData.minusSide.capillary.diameter = 1.3;
            } else {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.capillary.diameter = 0;
                this.utilsService.configurationData.minusSide.capillary.diameter = 0;
            }
            if (height === 0 || height === null || height === undefined) {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.heightInstrumentOverDiaphragmSeal = 0;
                this.utilsService.configurationData.minusSide.heightInstrumentOverDiaphragmSeal = 0;
            } else {
                this.utilsService.dataForCalculation.calculationRequest.minusSide.heightInstrumentOverDiaphragmSeal =
                    height;
                this.utilsService.configurationData.minusSide.heightInstrumentOverDiaphragmSeal =
                    height;
            }
        }
    }

    /**
     * Set Mounting data high side for capillary to calculation request
     * @param length: capillary length for high side
     * @param height: height difference of high side
     * @param diameter: inner diameter for high side
     */
    setMountingCapillary(length: number, height: number, diameter: number) {
        this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.length =
            Number(length);
        this.utilsService.configurationData.plusSide.capillary.length =
            Number(length);
        if (diameter) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter =
                Number(diameter);
            this.utilsService.configurationData.plusSide.capillary.diameter =
                Number(diameter);
        } else if (length) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter = 1.3;
            this.utilsService.configurationData.plusSide.capillary.diameter = 1.3;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.capillary.diameter = 0;
            this.utilsService.configurationData.plusSide.capillary.diameter = 0;
        }
        if (height === 0 || height === null) {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.heightInstrumentOverDiaphragmSeal = 0;
            this.utilsService.configurationData.plusSide.heightInstrumentOverDiaphragmSeal = 0;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.heightInstrumentOverDiaphragmSeal =
                height;
            this.utilsService.configurationData.plusSide.heightInstrumentOverDiaphragmSeal =
                height;
        }
    }

    /**
     * set process-parameter values to calculaation request
     * @param pMin : Minimum measuring range
     * @param pMax : Maximum measuring range
     * @param pstatic: static pressure
     * @param min : Minimum ambient temperature
     * @param normal: Nominal amibient tempearture
     * @param max: Maximum ambient temperature
     * @param processMin : Minimum process temperature
     * @param processNormal: Nominal process tempearture
     * @param processMax: Maximum process temperature
     * @param vaccumService: Negative pressure service
     */
    setProcessParametersData(
        pMin: number,
        pMax: number,
        pstatic: number,
        min: number,
        normal: number,
        max: number,
        processMin: number,
        processNormal: number,
        processMax: number,
        vaccumService: {vaccum_service:string}
    ) {
        this.utilsService.dataForCalculation.calculationRequest.pMin = pMin;
        this.utilsService.dataForCalculation.calculationRequest.pMax = pMax;
        this.utilsService.dataForCalculation.calculationRequest.tAmbient1 =
            Number(min);
        this.utilsService.dataForCalculation.calculationRequest.tAmbient2 =
            Number(normal);
        this.utilsService.dataForCalculation.calculationRequest.tAmbient3 =
            Number(max);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tAmbientMin =
            Number(min);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tAmbientMax =
            Number(max);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tProcessMin =
            Number(processMin);
        this.utilsService.dataForCalculation.calculationRequest.plusSide.tProcessMax =
            Number(processMax);
        this.setStaticPressure(pstatic);
        if (vaccumService.vaccum_service === '0') {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'None';
        } else if (vaccumService.vaccum_service === '1') {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'Standard';
        } else {
            this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService =
                'Extended';
        }
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide.tAmbientMin =
                Number(min);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tAmbientMax =
                Number(max);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tProcessMin =
                Number(processMin);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.tProcessMax =
                Number(processMax);

            this.utilsService.dataForCalculation.calculationRequest.minusSide.negativePressureService =
                this.utilsService.dataForCalculation.calculationRequest.plusSide.negativePressureService;
        }
    }

    /**
     * set static pressure for calculation request
     * @param pStatic : static pressure value
     */
    setStaticPressure(pStatic: number) {
        if (pStatic == null || Number.isNaN(pStatic)) {
            this.utilsService.dataForCalculation.calculationRequest.pMaxStatic =
                null;
        } else {
            this.utilsService.dataForCalculation.calculationRequest.pMaxStatic =
                Number(pStatic);
        }
    }

    /**
     * Sets process parameter values for result calculation
     * @param ambientMin :  Minimum ambient temperature
     * @param ambientNormal :Nominal amibient tempearture
     * @param ambientMax:Maximum ambient temperature
     * @param processMin: Minimum process temperature
     * @param processNormal: Nominal process tempearture
     * @param processMax: Maximum process temperature
     * @param pressureMin: Minimum measuring range
     * @param pressureMax: Maximum measuring range
     * @param staticPressure: static pressure
     * @returns calculaation data object
     */
    setCalculationData(
        ambientMin: number,
        ambientNormal: number,
        ambientMax: number,
        processMin: number,
        processNormal: number,
        processMax: number,
        pressureMin: number,
        pressureMax: number,
        staticPressure: number
    ) {
        this.utilsService.dataForCalculation.calculationData.tAmbientMin =
            Number(ambientMin);
        this.utilsService.dataForCalculation.calculationData.tAmbientMax =
            Number(ambientMax);
        this.utilsService.dataForCalculation.calculationData.tAmbientNormal =
            Number(ambientNormal);
        this.utilsService.dataForCalculation.calculationData.tProcessMin =
            Number(processMin);
        this.utilsService.dataForCalculation.calculationData.tProcessMax =
            Number(processMax);
        this.utilsService.dataForCalculation.calculationData.tProcessNormal =
            Number(processNormal);
        this.utilsService.dataForCalculation.calculationData.measuringSpanMin =
            Number(pressureMin);
        this.utilsService.dataForCalculation.calculationData.measuringSpanMax =
            Number(pressureMax);
        this.instrumentType = localStorage.getItem('InstrumentType');
        if (
            this.instrumentType === GlobalConstants.instrumentTypeAbs ||
            this.instrumentType === GlobalConstants.instrumentTypeRel ||
            this.instrumentType === GlobalConstants.instrumentTypeAbsdiff
        ) {
            this.utilsService.dataForCalculation.calculationData.staticPressure =
                null;
        } else {
            this.utilsService.dataForCalculation.calculationData.staticPressure =
                Number(staticPressure);
        }
        return this.utilsService.dataForCalculation.calculationData;
    }

    /**
     * Set extension length high side for calculation request
     * @param extensionLengthPlus : extension length
     */
    setExtensionLength(extensionLengthPlus: number) {
        this.utilsService.dataForCalculation.calculationRequest.plusSide.extensionLength =
            extensionLengthPlus;
    }

    /**
     * Set extension length low side for calculation request
     * @param extensionLengthMinus : extension length
     */
    setExtensionLengthMinusSide(extensionLengthMinus?: number) {
        if (
            this.utilsService.dataForCalculation.calculationRequest
                .minusSide !== null
        ) {
            this.utilsService.dataForCalculation.calculationRequest.minusSide.extensionLength =
                Number(extensionLengthMinus);
        }
    }

    /**
     * Set calculation request minus side to null when low side values are not selected
     */
    setMinusSideNull() {
        this.utilsService.dataForCalculation.calculationRequest.minusSide =
            null;
    }

    /**
     * Get the calculaation request object for http call
     * @returns the calculation request object
     */
    setRequestData() {
        return this.utilsService.dataForCalculation.calculationRequest;
    }

    /**
     * Calculate response type based on the instrument type selected
     */
    responseTimeCalc() {
        const type = localStorage.getItem('InstrumentType');
        const diffTypeConstant = 0.15;
        const singleTypeConst = 0.11;
        if (
            type === GlobalConstants.instrumentTypeDiff ||
            type === GlobalConstants.instrumentTypeLevel ||
            type === GlobalConstants.instrumentTypeAbsdiff
        ) {
            this.utilsService.dataForCalculation.calculatedResultData.t631 =
                this.resultData.responseTimes.tauForT1 + diffTypeConstant;
            this.utilsService.dataForCalculation.calculatedResultData.t632 =
                this.resultData.responseTimes.tauForT2 + diffTypeConstant;
            this.utilsService.dataForCalculation.calculatedResultData.t633 =
                this.resultData.responseTimes.tauForT3 + diffTypeConstant;
        } else {
            this.utilsService.dataForCalculation.calculatedResultData.t631 =
                this.resultData.responseTimes.tauForT1 + singleTypeConst;
            this.utilsService.dataForCalculation.calculatedResultData.t632 =
                this.resultData.responseTimes.tauForT2 + singleTypeConst;
            this.utilsService.dataForCalculation.calculatedResultData.t633 =
                this.resultData.responseTimes.tauForT3 + singleTypeConst;
        }
    }

    /**
     * Check if the overall system is green, yellow or red
     * @returns text to be displayed based on the overall system calculation
     */
    overallSystemText() {
        if (this.overallSystem >= 0 && this.overallSystem <= 0.9) {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'green';
            return 'TID_Ok';
        } else if (this.overallSystem > 0.9 && this.overallSystem <= 1) {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'yellow';
            return 'TID_OptimizationRecommended';
        } else {
            this.utilsService.internalConfigurationData.measuringpointObject.calculationStatus =
                'red';
            return 'TID_OptimizationNeccessary';
        }
    }

    /**
     * Check if the functionality/calibration/temperature error is green, yellow or red
     * @param value : the overall functionality/calibration/temperature error value
     * @returns text to be displayed
     */
    returnSystemOkOrNotOk(value) {
        if (value >= 0 && value <= 0.9) {
            return 'TID_Ok';
        } else if (value > 0.9 && value <= 1) {
            return 'TID_OptimizationRecommended';
        } else {
            return 'TID_OptimizationNeccessary';
        }
    }

    /**
     * check if the overall system is red , green or yellow and set the text accordingly in PDF
     */
    overallPdfText() {
        if (this.overallSystem >= 0 && this.overallSystem <= 0.9) {
            this.pdfText = this.translate.instant(
                'TID_TheSystemIsDimensionedCorrectly'
            );
        } else if (this.overallSystem > 0.9 && this.overallSystem <= 1) {
            this.pdfText = this.translate.instant(
                'TID_SystemDimensionedWithRestrictions'
            );
        } else {
            this.pdfText = this.translate.instant(
                'TID_SystemNotCorrectDimensioned'
            );
        }
        this.utilsService.dataForCalculation.calculatedResultData.text =
            this.pdfText;
    }

    /**
     * Calculate the overall functionality, calibration and temperature error value
     */
    overallSystemCalc() {
        const hundred=100;
        const zero=0;
        const dotNine=0.9;
        const dotOne=0.1;
        this.endPressure =
            Math.max(
                this.resultData.utilization.endPressure,
                this.resultData.utilization.endPressureMinus
            ) * hundred;
        this.volume =
            Math.max(
                this.resultData.utilization.volume,
                this.resultData.utilization.volumeMinus
            ) * hundred;
        this.offsetAdjustment =
            this.resultData.utilization.offsetAdjustment * hundred;
        this.customerOffsetAdjustment =
            this.resultData.utilization.customerOffsetAdjustment * hundred;
        this.spanAdjustment = this.resultData.utilization.spanAdjustment * hundred;
        this.gradientAdjustment =
            this.resultData.utilization.gradientAdjustment * hundred;
        this.functionality = Math.max(
            this.resultData.utilization.endPressure,
            this.resultData.utilization.volume,
            this.resultData.utilization.endPressureMinus,
            this.resultData.utilization.volumeMinus
        );
        this.calibration = Math.max(
            this.resultData.utilization.offsetAdjustment,
            this.resultData.utilization.spanAdjustment,
            this.resultData.utilization.gradientAdjustment
        );

        /////////////// Customer offset adjustment only with a green and yellow light /////////////////////
        if (
            this.calibration > zero &&
            this.calibration <= dotNine &&
            this.resultData.utilization.customerOffsetAdjustment > dotNine
        ) {
            this.calibration = 0.95;
        }
        ///////////////////////////End////////////////////////////////////////

        this.temperatureError = this.totalNormalMR / hundred;
        if (
            this.temperatureError > dotOne &&
            this.calibration > zero &&
            this.calibration <= dotNine &&
            this.functionality > zero &&
            this.functionality <= dotNine
        ) {
            this.overallSystem = 0.95;
        } else {
            this.overallSystem = Math.max(
                this.temperatureError,
                this.functionality,
                this.calibration
            );
        }
        this.utilsService.dataForCalculation.calculatedResultData.volume =
            this.volume;
        this.utilsService.dataForCalculation.calculatedResultData.endPressure =
            this.endPressure;
        this.utilsService.dataForCalculation.calculatedResultData.functionality =
            this.functionality;
        this.utilsService.dataForCalculation.calculatedResultData.span =
            this.spanAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.offset =
            this.offsetAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.customerOffset =
            this.customerOffsetAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.gradient =
            this.gradientAdjustment;
        this.utilsService.dataForCalculation.calculatedResultData.calibration =
            this.calibration;
        this.utilsService.dataForCalculation.calculatedResultData.temperatureError =
            this.temperatureError;
        this.utilsService.dataForCalculation.calculatedResultData.overallSystem =
            this.overallSystem;
        this.functionalityLabel = this.returnSystemOkOrNotOk(
            this.functionality
        );
        this.calibrationLabel = this.returnSystemOkOrNotOk(this.calibration);
        this.temperatureErrorLabel = this.returnSystemOkOrNotOk(
            this.temperatureError
        );
        this.overallPdfText();
    }

    /**
     * Calculate maximum temperature error for process and ambient temperature
     */
    remoteSealMaximumTempErrorCalculation() {
        const processMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tProcessMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const processMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tProcessMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        this.remoteSealProcessMax =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (Math.max(processMin, processMax) *
                Math.abs(this.resultData.errors.errorByProcessTemperature));
        const ambientMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const ambientMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        this.remoteSealAmbientMax =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (Math.max(ambientMin, ambientMax) *
                Math.abs(this.resultData.errors.errorByAmbientTemperature));
        this.remoteSealMaxProcessMeasuringRange =
            this.remoteSealProcessMax * this.resultData.mbarToPercentFactor;
        this.remoteSealMaxAmbientMeasuringRange =
            this.remoteSealAmbientMax * this.resultData.mbarToPercentFactor;
        this.remoteSealTotalMaxMeasuringRange =
            this.remoteSealMaxProcessMeasuringRange +
            this.remoteSealMaxAmbientMeasuringRange;
    }

    /**
     * Calculate the normal tempearture error for process and amnient temperature
     */
    remoteSealNormalTempErrorCalculation() {
        const processNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tProcessNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        this.remoteSeaProcessNormal =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (processNormal *
                Math.abs(this.resultData.errors.errorByProcessTemperature));
        const ambientNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tAmbientNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        this.remoteSealAmbientNormal =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (ambientNormal *
                Math.abs(this.resultData.errors.errorByAmbientTemperature));
        this.remoteSealProcessMeasuringRange =
            this.remoteSeaProcessNormal * this.resultData.mbarToPercentFactor;
        this.remoteSealAmbientMeasuringRange =
            this.remoteSealAmbientNormal * this.resultData.mbarToPercentFactor;
        this.remoteSealTotalNormalMeasuringRange =
            this.remoteSealProcessMeasuringRange +
            this.remoteSealAmbientMeasuringRange;
    }

    /**
     * obtain the tk1 and tk2 values from the EDD JSON for transmitter temperature calculation
     */
    transmitterTemperatureErrorCalc() {
        const elements =
            this.parserService.retrieveParametersFromUIElementWithName(
                'menu_Wizard_Transmitter_Error_Calculation'
            );
        for (const ele of elements) {
            if (
                ele.id ===
                this.utilsService.dataForCalculation.calculationData
                    .instrumentCode
            ) {
                this.parserService
                    .getEnumerationList(ele, this.parserService)
                    .then((val) => {
                        for (const each in val) {
                            if (
                                val[each].code ===
                                this.utilsService.dataForCalculation
                                    .calculationData.pNominal
                            ) {
                                this.tk1 = val[each].hasConcept?.prefLabel?.tk1;
                                this.tk2 = val[each].hasConcept?.prefLabel?.tk2;
                                this.temperatureErrorCalc();
                                break;
                            }
                        }
                    });
            }
        }
    }

    /**
     * Transmitter temperature error calculation
     */
    temperatureErrorCalc() {
       
        let pressureDiff =
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMax -
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMin;
                pressureDiff = this.convertPressureUnitValueIntoBar(pressureDiff);
        this.r =
            Math.abs(
                this.utilsService.dataForCalculation.calculationData.pNominal
            ) / Math.abs(pressureDiff);
        if (this.r < 1) {
            this.tempErr = this.tk1 * 1 + this.tk2;
        } else {
            this.tempErr = this.tk1 * this.r + this.tk2;
        }
        const ambientNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tAmbientNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        if (ambientNormal === 0) {
            this.transmitterAmbientNormalMR = 0;
        } else {
            this.transmitterAmbientNormalMR =
                (this.tempErr / this.twentyEight) * ambientNormal;
        }
        this.transmitterAmbientNormalTempErr =
            ((pressureDiff * this.transmitterAmbientNormalMR) /
                this.numberOfDecimalPlaces) *
            this.threeDecimalPlaces;
        const ambientMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const ambientMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        this.transmitterAmbientMaxMR =
            (this.tempErr / this.twentyEight) * Math.max(ambientMin, ambientMax);
        this.transmitterAmbientMaxTempErr =
            ((pressureDiff * this.transmitterAmbientMaxMR) /
                this.numberOfDecimalPlaces) *
            this.threeDecimalPlaces;
        let unitValue: number;

        //////////////Characteristic error///////////////
        this.transmitterNormalTemperatureErrorMR =
            this.transmitterAmbientNormalMR;
        this.transmitterNormalTemperatureErrorAbsolute =
            this.transmitterAmbientNormalTempErr;
        this.transmitterMaximumTemperatureErrorMR =
            this.transmitterAmbientMaxMR;
        this.transmitterMaximumTemperatureErrorAbsolute =
            this.transmitterAmbientMaxTempErr;
        this.CharacteristicerrorCalculation();
        this.transmitterAmbientNormalMR = Math.sqrt(
            Math.pow(this.transmitterAmbientNormalMR, this.two) +
                Math.pow(this.transmitterCharacteristicErrorMR, this.two)
        );
        this.transmitterAmbientNormalTempErr = Math.sqrt(
            Math.pow(this.transmitterAmbientNormalTempErr, this.two) +
                Math.pow(this.transmitterCharacteristicErrorTempErr, this.two)
        );
        this.transmitterAmbientMaxMR = Math.sqrt(
            Math.pow(this.transmitterAmbientMaxMR, this.two) +
                Math.pow(this.transmitterCharacteristicErrorMR, this.two)
        );
        this.transmitterAmbientMaxTempErr = Math.sqrt(
            Math.pow(this.transmitterCharacteristicErrorTempErr, this.two) +
                Math.pow(this.transmitterAmbientMaxTempErr, this.two)
        );
        ////////////////end/////////////////////

        this.dataStoreService
            .getData(this.dataStoreService.pressureUnit)
            .subscribe((value) => {
                if (value.includes('mbar')) {
                    this.unit = 'mbar';
                } else if (value.includes('kPa')) {
                    this.unit = 'kPa';
                } else {
                    this.unit = 'inch H20';
                }
                unitValue = this.unitHandlingService.pressureConversion(
                    this.unit
                );
            });
        this.utilsService.dataForCalculation.calculatedResultData.unit =
            this.unit;
        this.remoteSealAmbientNormal =
            (this.remoteSealAmbientNormal / this.threeDecimalPlaces) *
            unitValue;
        this.remoteSeaProcessNormal =
            (this.remoteSeaProcessNormal / this.threeDecimalPlaces) * unitValue;
        this.remoteSealAmbientMax =
            (this.remoteSealAmbientMax / this.threeDecimalPlaces) * unitValue;
        this.remoteSealProcessMax =
            (this.remoteSealProcessMax / this.threeDecimalPlaces) * unitValue;
        this.remoteSealTotalNormal =
            this.remoteSealAmbientNormal + this.remoteSeaProcessNormal;
        this.transmitterAmbientNormalTempErr =
            (this.transmitterAmbientNormalTempErr / this.threeDecimalPlaces) *
            unitValue;
        this.remoteSealTotalMax =
            this.remoteSealAmbientMax + this.remoteSealProcessMax;
        this.transmitterAmbientMaxTempErr =
            (this.transmitterAmbientMaxTempErr / this.threeDecimalPlaces) *
            unitValue;

            ///////////////
            this.transmitterNormalTemperatureErrorAbsolute =
            (this.transmitterNormalTemperatureErrorAbsolute / this.threeDecimalPlaces) *
            unitValue;
            this.transmitterMaximumTemperatureErrorAbsolute =
            (this.transmitterMaximumTemperatureErrorAbsolute / this.threeDecimalPlaces) *
            unitValue;
            ///////////////////////////////

        this.totalNormalMR =
            this.remoteSealTotalNormalMeasuringRange +
            this.transmitterAmbientNormalMR;
        this.totalNormalTempError =
            this.remoteSealTotalNormal + this.transmitterAmbientNormalTempErr;
        this.totalMaxMR =
            this.remoteSealTotalMaxMeasuringRange +
            this.transmitterAmbientMaxMR;
        this.totalMaxTempErr =
            this.remoteSealTotalMax + this.transmitterAmbientMaxTempErr;
        this.utilsService.dataForCalculation.calculatedResultData.totalNormalTempError =
            this.remoteSealTotalNormal + this.transmitterAmbientNormalTempErr;
        this.utilsService.dataForCalculation.calculatedResultData.totalNormalMRError =
            this.remoteSealTotalNormalMeasuringRange +
            this.transmitterAmbientNormalMR;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealTotalNormal =
            this.remoteSealTotalNormal;
        this.utilsService.dataForCalculation.calculatedResultData.transmitterTotalNormal =
            this.transmitterAmbientNormalTempErr;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealNormalMRError =
            this.remoteSealTotalNormalMeasuringRange;
        this.utilsService.dataForCalculation.calculatedResultData.transmitterNormalMRError =
            this.transmitterAmbientNormalMR;
        this.utilsService.dataForCalculation.calculatedResultData.totalMaxTempError =
            this.remoteSealTotalMax + this.transmitterAmbientMaxTempErr;
        this.utilsService.dataForCalculation.calculatedResultData.totalMaxMRError =
            this.remoteSealTotalMaxMeasuringRange +
            this.transmitterAmbientMaxMR;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealTotalMax =
            this.remoteSealTotalMax;
        this.utilsService.dataForCalculation.calculatedResultData.transmitterTotalMax =
            this.transmitterAmbientMaxTempErr;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealMaxMRError =
            this.remoteSealTotalMaxMeasuringRange;
        this.utilsService.dataForCalculation.calculatedResultData.transmitterMaxMRError =
            this.transmitterAmbientMaxMR;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealAmbientNormalTempError =
            this.remoteSealAmbientNormal;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealAmbientMaxTempError =
            this.remoteSealAmbientMax;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealAmbientNormalMR =
            this.remoteSealAmbientMeasuringRange;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealAmbientMaxMR =
            this.remoteSealMaxAmbientMeasuringRange;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealProcessNormalTempError =
            this.remoteSeaProcessNormal;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealProcessMaxTempError =
            this.remoteSealProcessMax;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealProcessNormalMR =
            this.remoteSealProcessMeasuringRange;
        this.utilsService.dataForCalculation.calculatedResultData.remoteSealProcessMaxMR =
            this.remoteSealMaxProcessMeasuringRange;
        this.overallSystemCalc();
    }

    convertTransmitterCharacteristicErrorIntoUnit()
    {
        const unitValue = this.unitHandlingService.pressureConversion(this.unit);
        this.transmitterCharacteristicErrorTempErr =
        (this.transmitterCharacteristicErrorTempErr / this.threeDecimalPlaces) *
        unitValue;
    }

    CharacteristicerrorCalculation() {
        const five=5;
        const transmitterCharacteristicErrorMRFirstConst=0.007;
        const transmitterCharacteristicErrorMRSecondConst=0.09;
        if (this.r > five) {
            this.transmitterCharacteristicErrorMR = transmitterCharacteristicErrorMRFirstConst * this.r + transmitterCharacteristicErrorMRSecondConst;
        } else {
            this.transmitterCharacteristicErrorMR = 0.125;
        }
        let pressureDiff =
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMax -
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMin;

                pressureDiff = this.convertPressureUnitValueIntoBar(pressureDiff);

        this.transmitterCharacteristicErrorTempErr =
            ((Math.abs(pressureDiff) * this.transmitterCharacteristicErrorMR) /
                this.numberOfDecimalPlaces) *
            this.threeDecimalPlaces;
    }
    /**
     * Get all the calculated results
     */
    calculatedResultData() {
        const celciousUnitValue='\xB0C';
        this.utilsService.dataForCalculation.calculationData.tReference=25;
        this.temperatureUnit =this.dataStoreService.getCurrentData(this.dataStoreService.temperatureUnit);
        this.convertTemperatureValueByUnit(celciousUnitValue);
        this.remoteSealMaximumTempErrorCalculation();
        this.remoteSealNormalTempErrorCalculation();
        this.transmitterTemperatureErrorCalc();
        this.responseTimeCalc();
    }

    convertTemperatureValueByUnit(unitValue:string)
    {
        const celciousUnitValue='\xB0C';
        if (this.temperatureUnit !== celciousUnitValue) {
            this.utilsService.dataForCalculation.calculationData.tProcessMin = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessMin,unitValue);
            this.utilsService.dataForCalculation.calculationData.tProcessNormal = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessNormal,unitValue);
            this.utilsService.dataForCalculation.calculationData.tProcessMax = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tProcessMax,unitValue);
            this.utilsService.dataForCalculation.calculationData.tAmbientMin = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientMin,unitValue);     
            this.utilsService.dataForCalculation.calculationData.tAmbientNormal = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientNormal,unitValue);
            this.utilsService.dataForCalculation.calculationData.tAmbientMax = this.unitHandlingService.temperatureConversion(this.utilsService.dataForCalculation.calculationData.tAmbientMax,unitValue);
            }
    }

    convertResultpageTempratureUnitBySelectedUnit()
    {
        const ferniteUnitValue='\xB0F';
            if(this.temperatureUnit===ferniteUnitValue)
            {
                this.utilsService.dataForCalculation.calculationData.tReference=77;
            }
            this.convertTemperatureValueByUnit(ferniteUnitValue);
    }

    // -------------------------New Code----------------------

    calculateResultFromLabom(result: ILabomResult) {
        const responseTimeResult = this.calculateResponseTime(
            result.responseTimes
        );
        const functionalityResponse = this.calculateFunctionality(
            result.utilization
        );
        const calibrationResponse = this.calculateCalibration(
            result.utilization
        );
        const {
            remoteSealNormalTempError,
            remoteSealMaximumTempError,
            transmitterTempError,
        } = this.calculateTempratureError(
            result.errors.errorByProcessTemperature,
            result.errors.errorByAmbientTemperature,
            result.mbarToPercentFactor
        );
      
         const unitValue = this.unitHandlingService.pressureConversion(this.unit);
        remoteSealNormalTempError.remoteSealAmbientNormal =
            (remoteSealNormalTempError.remoteSealAmbientNormal /
                this.threeDecimalPlaces) *
            unitValue;
        remoteSealNormalTempError.remoteSeaProcessNormal =
            (remoteSealNormalTempError.remoteSeaProcessNormal /
                this.threeDecimalPlaces) *
            unitValue;
        remoteSealMaximumTempError.remoteSealAmbientMax =
            (remoteSealMaximumTempError.remoteSealAmbientMax /
                this.threeDecimalPlaces) *
            unitValue;
        remoteSealMaximumTempError.remoteSealProcessMax =
            (remoteSealMaximumTempError.remoteSealProcessMax /
                this.threeDecimalPlaces) *
            unitValue;
        const remoteSealTotalNormal =
            remoteSealNormalTempError.remoteSealAmbientNormal +
            remoteSealNormalTempError.remoteSeaProcessNormal;
        transmitterTempError.transmitterAmbientNormalTempErr =
            (transmitterTempError.transmitterAmbientNormalTempErr /
                this.threeDecimalPlaces) *
            unitValue;

        transmitterTempError.transmitterAmbientMaxTempErr =
            (transmitterTempError.transmitterAmbientMaxTempErr /
                this.threeDecimalPlaces) *
            unitValue;


             ///////////////
             transmitterTempError.transmitterNormalTemperatureErrorAbsolute =
             (transmitterTempError.transmitterNormalTemperatureErrorAbsolute / this.threeDecimalPlaces) *
             unitValue;
             transmitterTempError.transmitterMaximumTemperatureErrorAbsolute =
             (transmitterTempError.transmitterMaximumTemperatureErrorAbsolute / this.threeDecimalPlaces) *
             unitValue;
             ///////////////////////////////

        const remoteSealTotalMax =
            remoteSealMaximumTempError.remoteSealAmbientMax +
            remoteSealMaximumTempError.remoteSealProcessMax;

        const totalNormalMR =
            remoteSealNormalTempError.remoteSealTotalNormalMeasuringRange +
            transmitterTempError.transmitterAmbientNormalMR;
        const totalNormalTempError =
            remoteSealTotalNormal +
            transmitterTempError.transmitterAmbientNormalTempErr;
        const totalMaxMR =
            remoteSealMaximumTempError.remoteSealTotalMaxMeasuringRange +
            transmitterTempError.transmitterAmbientMaxMR;
        const totalMaxTempErr =
            remoteSealTotalMax +
            transmitterTempError.transmitterAmbientMaxTempErr;

        return {
            ...responseTimeResult,
            ...functionalityResponse,
            ...calibrationResponse,
            totalNormalMR: totalNormalMR,
            totalNormalTempError: totalNormalTempError,
            totalMaxMR: totalMaxMR,
            totalMaxTempErr: totalMaxTempErr,
            remoteSealTotalMax: remoteSealTotalMax,
            remoteSealTotalNormal: remoteSealTotalNormal,
            ...remoteSealNormalTempError,
            ...remoteSealMaximumTempError,
            ...transmitterTempError,
        };
    }
    calculateResponseTime(responseFromLabom: {
        tauForT1: number;
        tauForT2: number;
        tauForT3: number;
    }): IResponseTime {
        const result: IResponseTime = <IResponseTime>{};
        const type = localStorage.getItem('InstrumentType');
        const diffTypeConstant = 0.15;
        const singleTypeConst = 0.11;
        if (
            type === GlobalConstants.instrumentTypeDiff ||
            type === GlobalConstants.instrumentTypeLevel ||
            type === GlobalConstants.instrumentTypeAbsdiff
        ) {
            result.t631 = responseFromLabom.tauForT1 + diffTypeConstant;
            result.t632 = responseFromLabom.tauForT2 + diffTypeConstant;
            result.t633 = responseFromLabom.tauForT3 + diffTypeConstant;
        } else {
            result.t631 = responseFromLabom.tauForT1 + singleTypeConst;
            result.t632 = responseFromLabom.tauForT2 + singleTypeConst;
            result.t633 = responseFromLabom.tauForT3 + singleTypeConst;
        }
        return result;
    }

    calculateFunctionality(labomUtilizationData: {
        customerOffsetAdjustment:number;
        endPressure:number;
        endPressureMinus:number;
        gradientAdjustment:number;
        offsetAdjustment:number;
        spanAdjustment:number;
        volume:number;
        volumeMinus:number;
    }) {
        const result = Math.max(
            labomUtilizationData.endPressure,
            labomUtilizationData.volume,
            labomUtilizationData.endPressureMinus,
            labomUtilizationData.volumeMinus
        );

        return { functionality: result };
    }

    calculateCalibration(labomUtilizationData: {
        volume: number;
        endPressure: number;
        customerOffsetAdjustment: number;
        offsetAdjustment: number;
        spanAdjustment: number;
        gradientAdjustment: number;
        volumeMinus: number;
        endPressureMinus: number;
    }) {
        const hundred=100;
        const offsetAdjustment = labomUtilizationData.offsetAdjustment * hundred;
        const customerOffsetAdjustment =
            labomUtilizationData.customerOffsetAdjustment * hundred;
        const spanAdjustment = labomUtilizationData.spanAdjustment * hundred;
        const gradientAdjustment =
            labomUtilizationData.gradientAdjustment * hundred;
        return {
            offsetAdjustment,
            customerOffsetAdjustment,
            spanAdjustment,
            gradientAdjustment,
        };
    }

    calculateRemoteSealMaximumTempError(
        errorByProcessTemperature,
        errorByAmbientTemperature,
        mbarToPercentFactor
    ) {
        const processMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tProcessMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const processMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tProcessMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const remoteSealProcessMax =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (Math.max(processMin, processMax) *
                Math.abs(errorByProcessTemperature));
        const ambientMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const ambientMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const remoteSealAmbientMax =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (Math.max(ambientMin, ambientMax) *
                Math.abs(errorByAmbientTemperature));
        const remoteSealMaxProcessMeasuringRange =
            remoteSealProcessMax * mbarToPercentFactor;
        const remoteSealMaxAmbientMeasuringRange =
            remoteSealAmbientMax * mbarToPercentFactor;
        const remoteSealTotalMaxMeasuringRange =
            remoteSealMaxProcessMeasuringRange +
            remoteSealMaxAmbientMeasuringRange;

        return {
            remoteSealProcessMax,
            remoteSealAmbientMax,
            remoteSealMaxProcessMeasuringRange,
            remoteSealMaxAmbientMeasuringRange,
            remoteSealTotalMaxMeasuringRange,
        };
    }

    calculateRemoteSealNormalTempError(
        errorByProcessTemperature,
        errorByAmbientTemperature,
        mbarToPercentFactor
    ) {
        const processNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tProcessNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const remoteSeaProcessNormal =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (processNormal * Math.abs(errorByProcessTemperature));
        const ambientNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tAmbientNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const remoteSealAmbientNormal =
            this.utilsService.dataForCalculation.calculatedResultData
                .factor[0] *
            (ambientNormal * Math.abs(errorByAmbientTemperature));
        const remoteSealProcessMeasuringRange =
            remoteSeaProcessNormal * mbarToPercentFactor;
        const remoteSealAmbientMeasuringRange =
            remoteSealAmbientNormal * mbarToPercentFactor;
        const remoteSealTotalNormalMeasuringRange =
            remoteSealProcessMeasuringRange + remoteSealAmbientMeasuringRange;

        return {
            remoteSeaProcessNormal,
            remoteSealAmbientNormal,
            remoteSealProcessMeasuringRange,
            remoteSealAmbientMeasuringRange,
            remoteSealTotalNormalMeasuringRange,
        };
    }

    CalculateTranmitterTemperatureError() {
        let transmitterAmbientNormalMR = 0;
        let transmitterAmbientNormalTempErr = 0;
        let transmitterAmbientMaxMR = 0;
        let transmitterAmbientMaxTempErr = 0;
        let transmitterNormalTemperatureErrorMR = 0;
        let transmitterNormalTemperatureErrorAbsolute = 0;
        let transmitterMaximumTemperatureErrorMR = 0;
        let transmitterMaximumTemperatureErrorAbsolute = 0;

        let pressureDiff =
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMax -
            this.utilsService.dataForCalculation.calculationData
                .measuringSpanMin;
                pressureDiff = this.convertPressureUnitValueIntoBar(pressureDiff);
        const ambientNormal = Math.abs(
            this.utilsService.dataForCalculation.calculationData
                .tAmbientNormal -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        if (ambientNormal === 0) {
            transmitterAmbientNormalMR = 0;
        } else {
            transmitterAmbientNormalMR = (this.tempErr / this.twentyEight) * ambientNormal;
        }
        transmitterAmbientNormalTempErr =
            ((pressureDiff * transmitterAmbientNormalMR) /
                this.numberOfDecimalPlaces) *
            this.threeDecimalPlaces;
        const ambientMin = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMin -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        const ambientMax = Math.abs(
            this.utilsService.dataForCalculation.calculationData.tAmbientMax -
                this.utilsService.dataForCalculation.calculationData.tReference
        );
        transmitterAmbientMaxMR =
            (this.tempErr / this.twentyEight) * Math.max(ambientMin, ambientMax);
        transmitterAmbientMaxTempErr =
            ((pressureDiff * transmitterAmbientMaxMR) /
                this.numberOfDecimalPlaces) *
            this.threeDecimalPlaces;

        transmitterNormalTemperatureErrorMR = transmitterAmbientNormalMR;
        transmitterNormalTemperatureErrorAbsolute =
            transmitterAmbientNormalTempErr;
        transmitterMaximumTemperatureErrorMR = transmitterAmbientMaxMR;
        transmitterMaximumTemperatureErrorAbsolute =
            transmitterAmbientMaxTempErr;

        transmitterAmbientNormalMR = Math.sqrt(
            Math.pow(transmitterAmbientNormalMR, this.two) +
                Math.pow(this.transmitterCharacteristicErrorMR, this.two)
        );
        transmitterAmbientNormalTempErr = Math.sqrt(
            Math.pow(transmitterAmbientNormalTempErr, this.two) +
                Math.pow(this.transmitterCharacteristicErrorTempErr, this.two)
        );
        transmitterAmbientMaxMR = Math.sqrt(
            Math.pow(transmitterAmbientMaxMR, this.two) +
                Math.pow(this.transmitterCharacteristicErrorMR, this.two)
        );
        transmitterAmbientMaxTempErr = Math.sqrt(
            Math.pow(this.transmitterCharacteristicErrorTempErr, this.two) +
                Math.pow(transmitterAmbientMaxTempErr, this.two)
        );

        return {
            transmitterNormalTemperatureErrorMR,
            transmitterNormalTemperatureErrorAbsolute,
            transmitterMaximumTemperatureErrorMR,
            transmitterMaximumTemperatureErrorAbsolute,
            transmitterAmbientNormalMR,
            transmitterAmbientNormalTempErr,
            transmitterAmbientMaxMR,
            transmitterAmbientMaxTempErr,
        };
    }

    calculateTempratureError(
        errorByProcessTemperature,
        errorByAmbientTemperature,
        mbarToPercentFactor
    ) {
        const remoteSealNormalTempError =
            this.calculateRemoteSealNormalTempError(
                errorByProcessTemperature,
                errorByAmbientTemperature,
                mbarToPercentFactor
            );
        const remoteSealMaximumTempError =
            this.calculateRemoteSealMaximumTempError(
                errorByProcessTemperature,
                errorByAmbientTemperature,
                mbarToPercentFactor
            );
        const transmitterTempError = this.CalculateTranmitterTemperatureError();
        return {
            remoteSealNormalTempError,
            remoteSealMaximumTempError,
            transmitterTempError,
        };
    }

    convertPressureUnitValueIntoBar(inputValue:number)
    {
        const barString='bar';
        const MPaString='MPa';
        const psiString='psi';
       const unitValue= this.dataStoreService.getCurrentData(this.dataStoreService.pressureUnit);
       let unit='';
        if (unitValue.includes(barString)) {
            unit = barString;
        } else if (unitValue.includes(MPaString)) {
            unit = MPaString;
        } else {
            unit = psiString;
        }
        const unitCoversationFactor = this.unitHandlingService.pressureConversion(unit);
       return inputValue / unitCoversationFactor;
       
    }
}
